import actions from '../actions'
import { ArrayFns } from '../util'

const defaultState = []

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.set_rent_rolls:
      return ArrayFns.uniq([...data, ...state], (x) => x.id).sort(
        (x, y) => x.order_nr - y.order_nr,
      )
    case actions.delete_rent_roll:
      return state.filter((x) => x.id !== data)
    default:
      return state
  }
}

export default reduce
