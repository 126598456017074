import { workstate, setRelatedItems } from '.'
import { Api } from 'src/util'

const _actions = ['set_activities', 'delete_activity', 'delete_flow']
const actions = {}

export const fetchActivities = (filter, userId) => (dispatch, getState) => {
  /**
   * fetch activities from server,
   * then use calendar event IDs from the activities to fetch corresponding events from outlook
   */
  return workstate(
    dispatch,
    `fetch-activities-${filter}-${userId}`,
    async () => {
      const json = await Api.get(
        `/activities${filter ? `?filter=${filter}` : ''}${
          userId ? (filter ? '&' : '?') + 'user=' + userId : ''
        }`,
        null,
        {},
      ).paginate(`activities-${userId}-${filter}`, dispatch, getState)

      dispatch({
        type: actions.set_activities,
        data: json.data,
      })
      if (json.additional_data && json.additional_data.related_items) {
        dispatch(setRelatedItems(json.additional_data.related_items))
      }
    },
  )
}

export const createActivity = (data) => (dispatch, getState) => {
  /**
   * create event in outlook,
   * then send event ID and extra activity data to server
   */
  return workstate(dispatch, 'create-activity', async () => {
    const json = await Api.post('/activities', data, {})

    dispatch({
      type: actions.set_activities,
      data: [json.data],
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const updateActivity = (id, data) => (dispatch, getState) => {
  /**
   * same here but update event and activity
   */
  return workstate(dispatch, `update-activity-${id}`, async () => {
    const json = await Api.patch(`/activities/${id}`, data, {})

    dispatch({
      type: actions.set_activities,
      data: [json.data],
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const deleteActivity = (id, flowID) => (dispatch, getState) => {
  return workstate(dispatch, 'delete-activity', async () => {
    await Api.delete(`/activities/${id}`, null, {})

    dispatch({
      type: actions.delete_activity,
      data: id,
    })
    if (flowID) {
      dispatch({
        type: actions.delete_flow,
        data: flowID,
      })
    }
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
