/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense } from 'react'
import ConfigModal from './ConfigModal'
import ConfirmModal from './ConfirmDialog'
import './index.scss'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Progress } from 'src/components'
import ErrorBoundary from 'src/ErrorBoundary'
import { setError } from 'src/actions'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root')

const _Modal = ({
  dispatch,
  error,
  working,
  noCloseOnSave,
  appWorking,
  ...props
}) => {
  const onSave = async (...params) => {
    if (props.onSave) await props.onSave(...params)
    if (!noCloseOnSave) onClose(...params)
  }

  const onClose = (e) => {
    props.onClose ? props.onClose(e) : dispatch(push(props.closePath))
  }

  let content
  if (props.data) {
    content = <ConfigModal {...props} onClose={onClose} onSave={onSave} />
  }
  if (props.alert) {
    content = <ConfirmModal {...props} onClose={onClose} onSave={onSave} />
  }

  return (
    <ReactModal
      isOpen={Boolean(props.active)}
      className={['modal', ...(props.className || '').split(' ')].join(' ')}
      // bodyOpenClassName=''
      overlayClassName='modal-container flex-container flex-center'
      onRequestClose={onClose}
      closeTimeoutMS={200}
    >
      <Progress working={!working && !error && appWorking} />
      <Suspense fallback={<Progress.Spinner />}>
        <ErrorBoundary name='Modal'>
          {content ||
            (typeof props.children === 'function'
              ? props.children({ onClose, onSave })
              : props.children)}
        </ErrorBoundary>
      </Suspense>
      <div
        className={
          'modal-working flex-container flex-center' +
          (working || error ? ' active' : '')
        }
      >
        {error ? (
          <div className='error flex-container flex-center'>
            <p>{error}</p>
            <button
              className='btn primary'
              onClick={() => dispatch(setError(null))}
            >
              Dismiss
            </button>
          </div>
        ) : (
          <Progress.Spinner />
        )}
      </div>
    </ReactModal>
  )
}

const mapStateToProps = ({ router, app }, { working, closePath }) => {
  return {
    error: app.error,
    working: working === undefined ? app.working.length > 0 : working,
    appWorking: app.working.length > 0,
    closePath: closePath || (router ? router.location.pathname : ''),
  }
}

export const Modal = connect(mapStateToProps)(_Modal)

Modal.propTypes = {
  error: PropTypes.string,
  working: PropTypes.bool,
  closePath: PropTypes.string,
  noCloseOnSave: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.object,
  alert: PropTypes.bool,
  className: PropTypes.string,
  active: PropTypes.bool.isRequired,
}

Modal.defaultProps = {
  active: false,
  className: '',
}
