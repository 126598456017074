import actions from '../actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  const obj = {}
  switch (type) {
    case actions.set_deals: {
      if (!data) return state
      for (const d of data) {
        obj[d.id] = {
          ...state[d.id],
          ...d,
        }
      }
      return {
        ...state,
        ...obj,
      }
    }
    case actions.add_deal_detail: {
      if (!data) return state
      for (const d of data) {
        obj[d.id] = {
          ...state[d.id],
          ...d,
          fully_loaded: true,
        }
      }
      return {
        ...state,
        ...obj,
      }
    }
    case actions.set_deal_participants: {
      return {
        ...state,
        [data.key]: {
          ...state[data.key],
          participants: data.value,
        },
      }
    }
    case actions.set_deal_activities: {
      return {
        ...state,
        [data.key]: {
          ...state[data.key],
          activities: data.value,
        },
      }
    }
    case actions.set_deal_followers: {
      return {
        ...state,
        [data.key]: {
          ...state[data.key],
          followers: data.value,
        },
      }
    }
    case actions.remove_deal: {
      const newState = { ...state }
      delete newState[data]
      return newState
    }
    default:
      return state
  }
}

export default reduce
