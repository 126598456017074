import actions from '../actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  const obj = {}
  switch (type) {
    case actions.set_persons_pfs:
      if (!data) return state
      for (const pfs of data) {
        obj[pfs.person_id] = {
          ...state[pfs.person_id],
          pfs: {
            ...(state[pfs.person_id] ? state[pfs.person_id].pfs : {}),
            ...pfs,
          },
        }
      }
      return {
        ...state,
        ...obj,
      }
    case actions.set_persons_sreo:
      if (!data) return state
      for (const sreo of data) {
        obj[sreo.person_id] = {
          ...state[sreo.person_id],
          sreo: {
            ...(state[sreo.person_id] ? state[sreo.person_id].sreo : {}),
            ...sreo,
          },
        }
      }
      return {
        ...state,
        ...obj,
      }
    default:
      return state
  }
}

export default reduce
