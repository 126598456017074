import { workstate } from '.'
import { Api } from 'src/util'
import { push } from 'connected-react-router'
import { format, parseISO } from 'date-fns'

const _actions = ['set_dashboards']
const actions = {}

export const fetchDashboards = () => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get('/statistics/dashboards')

    dispatch({
      type: actions.set_dashboards,
      data: json.data,
    })
  })
}

export const createDashboard = () => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.post('/statistics/dashboards')

    dispatch({
      type: actions.set_dashboards,
      data: [json.data],
    })
    const filters = json.data.filters
    const period = filters.period
    let range = period.type
    if (range === 'custom') {
      range = `${format(parseISO(period.start_date), 'yyyy-MM-dd')}_${format(
        parseISO(period.end_date),
        'yyyy-MM-dd',
      )}`
    }
    dispatch(
      push(
        `/progress/dashboard/${json.data.id}/${filters.pipeline_id}/${range}/${filters.user_id}`,
      ),
    )
  })
}

export const updateDashboard = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.patch(`/statistics/dashboards/${id}`, data)

    dispatch({
      type: actions.set_dashboards,
      data: [json.data],
    })
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
