import React from 'react'
import './index.scss'
import memoizeOne from 'memoize-one'

const renderNode = memoizeOne(
  ({ name, content, share }, hasChildren, onClick, isSelected) => {
    return (
      <div
        className={'node' + (hasChildren ? '' : ' no-child')}
        onClick={(e) => onClick(name)}
      >
        {name}
      </div>
    )
  }
)

const renderColumn = (
  { children, ...data },
  i,
  nodeRenderer,
  onClick,
  isSelected
) => {
  return (
    <li key={i}>
      {nodeRenderer(data, children && children.length > 0, onClick, isSelected)}
      {children && children.length > 0 && (
        <ul className='sub-list'>
          {children.map((x, i) =>
            renderColumn(x, i, nodeRenderer, onClick, isSelected)
          )}
        </ul>
      )}
    </li>
  )
}

export const OrgChart = React.forwardRef(
  (
    { readOnly, data, nodeRenderer, onClick, className, selected: isSelected },
    ref
  ) => {
    return (
      <div
        className={
          'chart chart-org' +
          (className ? ' ' + className : '') +
          (readOnly ? ' readonly' : '')
        }
      >
        <div className='chart-container'>
          <ul className='outer-list' ref={ref}>
            {data.map((x, i) =>
              renderColumn(
                x,
                i,
                nodeRenderer || renderNode,
                onClick,
                isSelected
              )
            )}
          </ul>
        </div>
      </div>
    )
  }
)
