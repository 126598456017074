import React, { useEffect, useReducer } from 'react'
import msalconnect from './msalconnect'
import { createStore as createAuthStore } from './store'

const AuthContext = React.createContext({})
const defaultState = {
  isAuthenticated: false,
  user: null,
  token: null,
  error: null,
}
const authedState = {
  ...defaultState,
  isAuthenticated: true,
  user: {
    name: 'test',
    email: 'test@test.test',
  },
  token: {
    idToken: {
      rawIdToken: 'TEST',
    },
  },
}
const reducer = (state = defaultState, { type, data }) => {
  switch (type) {
    case 'set_working':
      return {
        ...state,
        working: data,
      }
    case 'set_data':
      return {
        ...state,
        ...data,
      }
    default:
      return state
  }
}

const AuthProvider = ({ store, children }) => {
  const [state, dispatch] = useReducer(reducer, store)
  useEffect(() => {
    // weird hack to update the state the first time
    state.dispatch = dispatch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getState = () =>
    process.env.NODE_ENV === 'test' || window.Cypress ? authedState : state

  return (
    <AuthContext.Provider value={{ store: { dispatch, getState } }}>
      {children}
    </AuthContext.Provider>
  )
}

export { msalconnect, AuthContext, AuthProvider, createAuthStore }
