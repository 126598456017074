import React from 'react'
import Field from '../Field'

const Element = (props) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'paragraph':
      return <p>{children}</p>
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'link':
      return (
        <a
          {...attributes}
          href={element.url}
          target='_blank'
          rel='noreferrer noopener'
        >
          {children}
        </a>
      )
    case 'underline':
      return <u {...attributes}>{children}</u>
    case 'bold':
      return <strong {...attributes}>{children}</strong>
    case 'italic':
      return <em {...attributes}>{children}</em>
    case 'strikethrough':
      return <s {...attributes}>{children}</s>
    case 'code':
      return <code {...attributes}>{children}</code>
    case 'field':
      return <Field {...props} />
    default:
      return <div {...attributes}>{children}</div>
  }
}

export default Element
