export default {
  chunkParams (size) {
    const maxSize = 4000000 // 4MiB ish, max upload size of azure fileshare
    const allsize = size
    const sep = allsize < maxSize ? allsize : maxSize - 1
    const ar = []
    for (let i = 0; i < allsize; i += sep) {
      const bstart = i
      const bend = i + sep - 1 < allsize ? i + sep - 1 : allsize - 1
      const cr = 'bytes ' + bstart + '-' + bend + '/' + allsize
      const clen = bend !== allsize - 1 ? sep : allsize - i
      const stime = allsize < maxSize ? 5000 : 10000
      ar.push({
        bstart: bstart,
        bend: bend,
        cr: cr,
        clen: clen,
        stime: stime,
      })
    }
    return ar
  },
  fileSize: (size) => {
    if (!size) return '0 B'
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      ' ' +
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    )
  },
}
