import actions from 'src/actions'
const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.set_summary:
      if (!data) return state
      return {
        ...state,
        [data.key]: data.value,
      }
    default:
      return state
  }
}

export default reduce
