import Color from './color'
import Timers from './timers'
import Api from './api'
import Constants from './constants'
import ArrayFns from './array'
import Files from './files'
import DragDrop from './dragdrop'
import * as Errors from './error'
export * from './clsx'

class Money {
  static fmt (dollars = 0) {
    if (!isFinite(dollars) || isNaN(dollars)) return '-'
    const cents = dollars * 100
    let sign = cents < 0 ? '-' : ''
    if (isNaN(cents)) return ''
    const nums = Math.round(Math.abs(+cents))
      .toString()
      .replace(/\D/g, '')
      .split('')
    let str = ''
    let end = ''
    if (!nums.some((x) => x !== '0')) sign = ''
    if (nums.length < 3) {
      // less than a dollar
      if (nums.length === 1) nums.unshift(0)
      const res = `$${sign}0.${nums.join('')}`
      if (res === '$0.00') return '$0'
      return res
    }

    end = (nums.pop() + nums.pop()).split('').reverse().join('')
    if (end === '00') end = ''
    else end = `.${end}`

    let next
    let i = 0
    while ((next = nums.pop()) !== undefined) {
      str = next + str
      if (++i % 3 === 0) str = ',' + str
    }
    if (str.startsWith(',')) str = str.substr(1)
    return `$${sign}${str}${end}`
  }

  static pmt (ir, np, pv, fv, type) {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    let res

    fv || (fv = 0)
    type || (type = 0)

    if (ir === 0) return -(pv + fv) / np

    const pvif = Math.pow(1 + ir, np)
    res = (-ir * pv * (pvif + fv)) / (pvif - 1)

    if (type === 1) res /= 1 + ir

    return res
  }
}

class Format {
  static get money () {
    return Money.fmt
  }

  static number (x = 0) {
    return x.toLocaleString()
    // return ('' + x).replace(/(.)(?=(\d{3})+$)/g,'$1,')
  }

  static plural (s = '', n, hint) {
    const copy = s.toLowerCase()
    n = +n
    const res =
      n === 1
        ? copy
        : hint
          ? copy + hint
          : copy.endsWith('y')
            ? copy.slice(0, copy.length - 1) + 'ies'
            : copy + 's'
    if (s === s.toLowerCase()) return res.toLowerCase()
    else if (s === s.toUpperCase()) return res.toUpperCase()
    else if (s[0] === s[0].toUpperCase()) return Format.capitalize(res)
  }

  static capitalize (s = '') {
    if (!s?.length) return ''
    return s[0].toUpperCase() + s.substr(1).toLowerCase()
  }

  static titleize (s = '') {
    return s.split(' ').map(Format.capitalize).join(' ')
  }

  static single (s = '') {
    const parts = s.toLowerCase().split(' ')
    const copy = parts.pop()
    let res
    switch (copy) {
      case 'feet':
        res = 'foot'
        break
      default:
        if (copy.endsWith('ies')) res = copy.slice(0, s.length - 3) + 'y'
        else if (copy.endsWith('s')) res = copy.slice(0, s.length - 1)
        else res = copy
        break
    }
    res = parts.concat(res).join(' ')
    if (s === s.toLowerCase()) return res.toLowerCase()
    else if (s === s.toUpperCase()) return res.toUpperCase()
    else if (s[0] === s[0].toUpperCase()) return Format.capitalize(res)
  }

  static time (msec) {
    const dd = Math.floor(msec / 24 / 1000 / 60 / 60)
    msec -= dd * 24 * 1000 * 60 * 60
    const hh = Math.floor(msec / 1000 / 60 / 60)
    msec -= hh * 1000 * 60 * 60
    const mm = Math.floor(msec / 1000 / 60)
    msec -= mm * 1000 * 60
    const ss = Math.floor(msec / 1000)
    msec -= ss * 1000
    return `${
      dd ? dd + Format.plural('day', dd, 's') + (hh || mm ? ', ' : '') : ''
    }${hh ? `${hh} ${Format.plural('hour', hh)}` + (mm ? ', ' : '') : ''}${
      mm ? `${mm} ${Format.plural('minute', mm)}` : ''
    }`
  }
}

class Numbers {
  static clamp (x, min = -Infinity, max = Infinity) {
    const isString = typeof x === 'string'
    if (max < min) [max, min] = [min, max]
    const res = Math.max(min, Math.min(max, +x))
    if (isString) return res.toString()
    return res
  }
}

export {
  Money,
  Format,
  Color,
  Numbers,
  Timers,
  Api,
  Constants,
  ArrayFns,
  Files,
  Errors,
  DragDrop,
}
