/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import './index.scss'

export const Dropdown = ({
  className,
  open,
  styleRef,
  setOpen,
  container,
  header,
  footer,
  items,
  itemRenderer,
  namefn,
  index,
  onSelect,
  position: _position,
}) => {
  const close = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    window.addEventListener('mousedown', close)
    return () => window.removeEventListener('mousedown', close)
  }, [])

  if (!open) return null

  const position = _position
    ? { width: 240, height: 0, ..._position }
    : container.current && container.current.getBoundingClientRect()

  const onClick = (e, x) => {
    if (onSelect) onSelect(e, x)
    setOpen(false)
  }

  const isNearTop = position && position.y < window.innerHeight / 2
  const containerHeight = position ? position.height : 32
  styleRef.current = {
    container: {
      top: isNearTop ? position.y + containerHeight + 2 : 'initial',
      bottom: isNearTop ? 'initial' : window.innerHeight - position.y + 2,
      right: window.innerWidth - position.x - position.width,
      minWidth: Math.max(position.width || 0, 200),
      maxHeight:
        (isNearTop ? window.innerHeight - position.y : position.y) -
        containerHeight -
        14,
    },
    list: {
      maxHeight:
        (isNearTop ? window.innerHeight - position.y : position.y) -
        containerHeight -
        14,
    },
  }

  const stopProp = (e) => {
    e.persist?.()
    e.stopPropagation()
  }

  return createPortal(
    <div
      className={'dropdown ' + className}
      onClick={stopProp}
      style={styleRef.current && styleRef.current.container}
      onMouseDown={stopProp}
    >
      {typeof header === 'function' ? header({ close }) : header}
      {items.length > 0 && (
        <ul className='items' style={styleRef.current && styleRef.current.list}>
          {items.map((x, i) => {
            if (x === 'divider') return <li className='divider' key={i} />
            return (
              <li key={i} onClick={(e) => onClick(e, x)}>
                {itemRenderer
                  ? itemRenderer(x, i, i === index)
                  : namefn
                    ? namefn(x)
                    : x}
              </li>
            )
          })}
        </ul>
      )}
      {typeof footer === 'function' ? footer({ close }) : footer}
    </div>,
    document.querySelector('body')
  )
}

Dropdown.defaultProps = {
  items: [],
  index: 0,
  onSelect: () => null,
  className: '',
}

Dropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  styleRef: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  container: PropTypes.object,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  itemRenderer: PropTypes.func,
  namefn: PropTypes.func,
  index: PropTypes.number,
  onSelect: PropTypes.func,
}
