import { workstate, setRelatedItems } from './index'
import { Api } from 'src/util'

const _actions = ['set_teams', 'delete_team']
const actions = {}

export const fetchTeams = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    let json
    if (id) {
      json = await Api.get(`/teams/${id}`)
      dispatch({
        type: actions.set_teams,
        data: [json.data],
      })
    } else {
      json = await Api.get('/teams').paginate('team', dispatch, getState)
      dispatch({
        type: actions.set_teams,
        data: json.data,
      })
    }
  })
}

export const createTeam = (data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.post('/teams', data, {})

    dispatch({
      type: actions.set_teams,
      data: [json.data],
    })

    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const updateTeam = (id, data) => (dispatch, getState) => {
  const d = { ...data }
  delete d.id
  delete d.created_at
  delete d.updated_at
  return workstate(dispatch, async () => {
    const json = await Api.patch(`/teams/${id}`, d, {})

    dispatch({
      type: actions.set_teams,
      data: [json.data],
    })

    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const reorderTeams = (data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.patch('/bulk/teams', data)

    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const deleteTeam = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    await Api.delete(`/teams/${id}`, null, {})

    dispatch({
      type: actions.delete_team,
      data: id,
    })
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
