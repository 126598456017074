import actions from 'src/actions'
const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  // console.log(type, data)
  switch (type) {
    case actions.set_pipelines: {
      if (!data) return state
      const obj = {}
      for (const pipeline of data) {
        obj[pipeline.id] = {
          ...state[pipeline.id],
          ...pipeline,
        }
      }
      return {
        ...state,
        ...obj,
      }
    }
    case actions.remove_pipeline: {
      const newState = { ...state }
      delete newState[data]
      return {
        ...newState,
      }
    }
    default:
      return state
  }
}

export default reduce
