import actions from '../actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.add_page: {
      const { resourceKey, resourceIds, offset, more } = data
      const pages = [...(state[resourceKey] || [])]

      if (pages.some((x) => x.nextOffset >= offset)) return state

      pages.push({
        ids: resourceIds,
        nextOffset: offset,
        more,
      })

      return {
        ...state,
        [resourceKey]: pages,
      }
    }
    case actions.reset_page: {
      const { resourceKey } = data
      const newState = { ...state }
      delete newState[resourceKey]
      return newState
    }
    default:
      return state
  }
}

export default reduce
