import actions from '../actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  let obj = {}
  switch (type) {
    case actions.set_flows:
      if (!data) return state
      for (const x of data) {
        obj[x.id] = {
          ...obj[x.id],
          ...x,
        }
      }
      return {
        ...state,
        ...obj,
      }
    case actions.delete_flow:
      obj = { ...state }
      delete obj[data]
      return {
        ...obj,
      }
    case actions.update_flow:
      obj[data.key] = {
        ...obj[data.key],
        ...data.value,
      }
      return {
        ...state,
        ...obj,
      }
    default:
      return state
  }
}

export default reduce
