import actions from 'src/actions'
import { ArrayFns, Constants } from 'src/util'

let defaultState = {}
if (localStorage.getItem(Constants.LOCALSTORAGE_KEY)) {
  const data = JSON.parse(localStorage.getItem(Constants.LOCALSTORAGE_KEY))
  defaultState = data.mailboxes || {}
}
// mmmmmmm state that doesnt go away

const getName = (name) => {
  return name.toLowerCase().replace(/\s/g, '')
}

const saveDelta = (mailbox, deltaLink) => {
  let data = {}
  if (localStorage.getItem(Constants.LOCALSTORAGE_KEY)) {
    data = JSON.parse(localStorage.getItem(Constants.LOCALSTORAGE_KEY))
  }
  if (!data.mailboxes) data.mailboxes = {}
  data.mailboxes[mailbox] = {
    delta: deltaLink,
  }
  localStorage.setItem(Constants.LOCALSTORAGE_KEY, JSON.stringify(data))
}

const reduce = (state = defaultState, { type, data }) => {
  let key = ''
  const mailboxes = {}
  switch (type) {
    case actions.set_mailboxes:
      for (const box of data) {
        key = getName(box.displayName)
        mailboxes[key] = {
          ...state[key],
          ...box,
          messages: [],
          next: '',
        }
      }
      return {
        ...state,
        ...mailboxes,
      }
    case actions.set_mailboxes_silent:
      for (const box of data) {
        key = getName(box.displayName)
        mailboxes[key] = {
          ...state[key],
          ...box,
        }
      }
      return {
        ...state,
        ...mailboxes,
      }
    case actions.set_mailbox:
      key = getName(data.displayName)
      return {
        ...state,
        [key]: {
          ...state[key],
          ...data,
        },
      }
    case actions.set_mailbox_messages:
      const stateMessages = state[data.mailbox]?.messages ?? [];
      const ms = data.addToStart
        ? [...data.messages, ...stateMessages]
        : [...stateMessages, ...data.messages]
      const res = {
        ...state[data.mailbox],
        messages: ArrayFns.uniq(ms),
      }
      if (data.delta) {
        saveDelta(data.mailbox, data.delta)
        res.delta = data.delta
      }
      if (data.next) {
        res.next = data.next
      }
      return {
        ...state,
        [data.mailbox]: res,
      }
    default:
      return state
  }
}

export default reduce
