import { workstate, setRelatedItems } from '.'
import { Api } from 'src/util'

const _actions = ['set_availabilities', 'delete_availability']
const actions = {}

export const fetchAvailabilities = () => (dispatch, getState) => {
  return workstate(dispatch, 'fetch-availabilities', async () => {
    const json = await Api.get('/availabilities').paginate(
      'availabilities',
      dispatch,
      getState,
    )

    dispatch({
      type: actions.set_availabilities,
      data: json.data,
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const fetchAvailability = (id) => (dispatch) => {
  return workstate(dispatch, `fetch-availability-${id}`, async () => {
    const json = await Api.get('/availabilities/' + id)

    dispatch({
      type: actions.set_availabilities,
      data: [json.data],
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const fetchAnonAvailability = (secret) => (dispatch) => {
  return workstate(dispatch, 'fetch-availability', async () => {
    const json = await Api.anon.get('/availabilities-public/' + secret)

    dispatch({
      type: actions.set_availabilities,
      data: [json.data],
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const confirmAnonAvailability = (secret, time) => (dispatch) => {
  return workstate(dispatch, 'confirm-availability', async () => {
    if (time) {
      await Api.anon.post(`/availabilities-public/${secret}/confirm`, {
        time,
      })
    } else {
      await Api.anon.post(`/availabilities-public/${secret}/deny`)
    }
  })
}

export const createAvailability = (data) => (dispatch) => {
  return workstate(dispatch, 'create-availability', async () => {
    const json = await Api.post('/availabilities', data)

    dispatch({
      type: actions.set_availabilities,
      data: [json.data],
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const updateAvailability = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, `update-availability-${id}`, async () => {
    const json = await Api.patch(`/availabilities/${id}`, data)

    dispatch({
      type: actions.set_availabilities,
      data: [json.data],
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const deleteAvailability = (id) => (dispatch, getState) => {
  return workstate(dispatch, 'delete-availability', async () => {
    await Api.delete(`/availabilities/${id}`, null)

    dispatch({
      type: actions.delete_availability,
      data: id,
    })
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
