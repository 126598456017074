import * as MSGraph from '@microsoft/microsoft-graph-client'

function getAuthenticatedClient (token) {
  const client = MSGraph.Client.init({
    authProvider (done) {
      done(null, token.accessToken)
    },
  })
  return client
}

export default class {
  static async getUserDetails (token) {
    const client = getAuthenticatedClient(token)
    return client.api('/me').get()
  }
}

export { getAuthenticatedClient }
