import { HttpError } from './error'
import { paginationAddPage } from 'src/actions'
const url = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:7071'
let _token, _onError

const ready = () => {
  return new Promise((resolve) => {
    if (_token || process.env.NODE_ENV === 'test' || window.Cypress) {
      return resolve()
    }
    const interval = setInterval(() => {
      if (_token) {
        clearInterval(interval)
        resolve()
      }
    }, 100)
  })
}

class Client {
  constructor (def = (res, rej) => {}, u, data, opts) {
    this.paramsObject = {}
    this.storeObject = {
      onResult: () => {},
    }
    const promise = new Promise(async (resolve, reject) => {
      try {
        const result = await this.query(u, data, opts)
        if (this.storeObject.onResult) this.storeObject.onResult(result)
        resolve(result)
      } catch (e) {
        reject(e)
      }
    })
    return Object.assign(promise, {
      paramsObject: this.paramsObject,
      storeObject: this.storeObject,
      query: this.query,
      paginate: this.paginate,
    })
  }

  async query (u, data, opts) {
    let params = []
    for (const k in this.paramsObject) {
      params.push(`${k}=${this.paramsObject[k]}`)
    }
    params = params.join('&')

    const connector = params.length ? (u.includes('?') ? '&' : '?') : ''
    return await ajax(u + connector + params, data, opts, _token, false)
  }

  paginate (resource, dispatch, getState) {
    if (!dispatch) throw new Error('missing dispatch')
    if (!getState) throw new Error('missing getState')

    const { pagination } = getState()
    const pages = pagination && pagination[resource]

    if (!pages || !pages.length) {
    } else {
      // const last = pages[pages.length - 1]
      this.paramsObject = {
        ...this.paramsObject,
        // skip: last.offset
      }
    }
    this.storeObject.onResult = (res) => {
      if (!res.additional_data?.pagination) return
      const {
        additional_data: { pagination: paginationData },
        data,
      } = res
      const hasMore = paginationData.more_items_in_collection

      dispatch(
        paginationAddPage(
          resource,
          data.map((x) => x.id),
          paginationData.skip,
          hasMore,
        ),
      )
    }

    return this
  }
}

async function ajax (
  u,
  data,
  { headers, parse, token: _injectedToken, ...opts } = {},
  token,
  isRetry,
) {
  if (!u.startsWith('/')) {
    console.warn(`ajax url should start with '/' (was given ${u})`)
  }

  if (!token && _injectedToken) token = _injectedToken

  await ready()
  if (!token && process.env.NODE_ENV !== 'test') {
    throw new HttpError(403, 'Missing access token')
  }

  let res
  try {
    if (token && !token.NO_USER && !headers?.Authorization) {
      headers = {
        ...headers,
        Authorization: 'Bearer ' + token,
      }
    }
    res = await fetch(url + u, {
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: data && parse ? JSON.stringify(data) : undefined,
      ...opts,
    })
  } catch (e) {
    if (e instanceof TypeError) {
      const err = new HttpError(0, e.message)
      if (_onError) _onError(err)
      throw err
    }
  }

  if (res.status.toString().startsWith('2')) {
    if (res.status === 204) {
    } else {
      if (parse) {
        try {
          return res.json()
        } catch (e) {
          return {}
        }
      } else return res.blob()
    }
  } else {
    if (isRetry) {
      return {
        error: 'token refresh failed',
      }
    }
    let json
    try {
      json = await res.json()
    } catch (e) {
      const err = new HttpError(res.status, 'No response returned by server')
      if (_onError) _onError(err)
      throw err
    }
    const err = new HttpError(
      res.status,
      json.message ||
        (json.error && json.error.message ? json.error.message : json.error) ||
        res.status,
    )
    if (_onError) _onError(err)
    throw err
  }
}

const ajaxApi = {
  ajax,
  get (u, data, opts = {}) {
    const client = new Client((r) => r(), u, data, {
      parse: true,
      ...opts,
      method: 'GET',
    })
    return client
  },
  async post (u, data, opts = {}) {
    return await ajax(
      u,
      data,
      {
        parse: true,
        ...opts,
        method: 'POST',
      },
      _token,
    )
  },
  async delete (u, data, opts = {}) {
    return await ajax(
      u,
      null,
      {
        parse: true,
        ...opts,
        method: 'DELETE',
      },
      _token,
    )
  },
  async patch (u, data, opts = {}) {
    return await ajax(
      u,
      data,
      {
        parse: true,
        ...opts,
        method: 'PATCH',
      },
      _token,
    )
  },
  async put (u, data, opts = {}) {
    return await ajax(
      u,
      data,
      {
        parse: true,
        ...opts,
        method: 'PUT',
      },
      _token,
    )
  },
  setToken (token) {
    _token = token
  },
  setOnError (fn) {
    _onError = fn
  },
}

ajaxApi.anon = Object.keys(ajaxApi).reduce(
  (a, x) => ({
    ...a,
    [x]: (u, d, o) =>
      ajaxApi[x](u, d, { ...o, token: { NO_USER: true } }, { NO_USER: true }),
  }),
  {},
)

export default ajaxApi
