import { workstate, setRelatedItems } from '.'
import { Api } from 'src/util'
import saveAs from 'file-saver'
import { push } from 'connected-react-router'

const _actions = [
  'set_organizations',
  'set_organizations_detail',
  'set_organization_fields',
  'remove_organization',
  'set_flows',
]
const actions = {}

export const fetchOrganizations =
  (search, userId, offset = 0) =>
    (dispatch, getState) => {
      return workstate(
        dispatch,
      `fetch-organiztions-${userId}-{search}`,
      async () => {
        const json = await Api.get(
          `/organizations?skip=${offset}${search ? `&search=${search}` : ''}${
            userId ? '&user=' + userId : ''
          }`,
          null,
          {},
        ).paginate(`organizations-${userId}-${search}`, dispatch, getState)

        dispatch({
          type: actions.set_organizations,
          data: json.data,
        })

        if (json.additional_data && json.additional_data.related_items) {
          dispatch(setRelatedItems(json.additional_data.related_items))
        }
      },
      )
    }

export const fetchOrganization = (id) => (dispatch, getState) => {
  return workstate(dispatch, `fetch-organization-${id}`, async () => {
    if (!id) throw new Error('missing org id to fetch')
    const json = await Api.get(`/organizations/${id}`, null, {})

    dispatch({
      type: actions.set_organizations_detail,
      data: [json.data],
    })

    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const fetchOrganizationChart = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get(`/organizations/${id}/chart`, null)

    dispatch({
      type: actions.set_organizations_detail,
      data: json.data,
    })
  })
}

export const updateOrganizationChart = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.patch(`/organizations/${id}/chart`, data)

    dispatch({
      type: actions.set_organizations_detail,
      data: json.data,
    })
  })
}

export const exportOrganizationChart =
  (id, name, blob, download) => (dispatch, getState) => {
    return workstate(dispatch, async () => {
      const data = await Api.post(
        `/organizations/${id}/export-chart?download=${download}`,
        null,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          body: blob,
          parse: false,
        },
      )

      if (download) {
        saveAs(
          new Blob([data], { type: 'application/pdf' }),
          `${name}_chart.pdf`,
        )
      }
    })
  }

export const fetchOrganizationFields = () => (dispatch, getState) => {
  return workstate(dispatch, 'fetch-organization-fields', async () => {
    const json = await Api.get('/organizationFields', null, {})

    dispatch({
      type: actions.set_organization_fields,
      data: json.data,
    })
  })
}

export const createOrganization = (data) => (dispatch, getState) => {
  return workstate(dispatch, 'create-organization', async () => {
    const json = await Api.post('/organizations', data, {})

    dispatch({
      type: actions.set_organizations_detail,
      data: [json.data],
    })

    return json.data
  })
}

export const updateOrganization = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, `update-organization-${id}`, async () => {
    const d = { ...data }
    delete d.id
    delete d.created_at
    delete d.updated_at
    delete d.fully_loaded
    const json = await Api.patch(`/organizations/${id}`, d, {})

    dispatch({
      type: actions.set_organizations_detail,
      data: [json.data],
    })
  })
}

export const deleteOrganization =
  (id, returnToOrgs) => (dispatch, getState) => {
    return workstate(dispatch, `delete-organization-${id}`, async () => {
      await Api.delete(`/organizations/${id}`, null, {})

      dispatch({
        type: actions.remove_organization,
        data: id,
      })
      if (returnToOrgs) dispatch(push('/contacts/organizations'))
    })
  }

export const fetchOrganizationFlow = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get(`/organizations/${id}/flow`, null, {}).paginate(
      `organizations_flow-${id}`,
      dispatch,
      getState,
    )

    dispatch({
      type: actions.set_flows,
      data: json.data,
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
