import { workstate, setRelatedItems } from '.'
import { Api } from 'src/util'
import { push } from 'connected-react-router'

const _actions = [
  'set_persons',
  'set_persons_detail',
  'set_person_fields',
  'set_persons_pfs',
  'set_persons_sreo',
  'remove_person',
  'set_flows',
]
const actions = {}

export const fetchPersons =
  (search, userId, offset = 0) =>
    (dispatch, getState) => {
      return workstate(dispatch, `fetch-persons-${userId}-{search}`, async () => {
        const json = await Api.get(
        `/persons?skip=${offset}${search ? `&search=${search}` : ''}${
          userId ? '&user=' + userId : ''
        }`,
        null,
        {},
        ).paginate(`persons-${userId}-${search}`, dispatch, getState)

        dispatch({
          type: actions.set_persons,
          data: json.data,
        })

        if (json.additional_data && json.additional_data.related_items) {
          dispatch(setRelatedItems(json.additional_data.related_items))
        }
      })
    }

export const fetchPerson = (id) => (dispatch, getState) => {
  return workstate(dispatch, `fetch-person-${id}`, async () => {
    const json = await Api.get(`/persons/${id}`, null, {})

    dispatch({
      type: actions.set_persons_detail,
      data: [json.data],
    })

    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const updatePerson = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, `update-person-${id}`, async () => {
    const d = { ...data }
    delete d.id
    delete d.created_at
    delete d.updated_at
    delete d.fully_loaded
    const json = await Api.patch(`/persons/${id}`, d, {})

    dispatch({
      type: actions.set_persons_detail,
      data: [json.data],
    })
  })
}

export const deletePerson = (id, returnToPersons) => (dispatch, getState) => {
  return workstate(dispatch, `delete-person-${id}`, async () => {
    await Api.delete(`/persons/${id}`, null, {})

    dispatch({
      type: actions.remove_person,
      data: id,
    })
    if (returnToPersons) dispatch(push('/contacts/people'))
  })
}

export const fetchPersonFields = () => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get('/personFields', null, {})

    dispatch({
      type: actions.set_person_fields,
      data: json.data,
    })
  })
}

export const createPerson = (data) => (dispatch, getState) => {
  return workstate(dispatch, 'create-person', async () => {
    const json = await Api.post('/persons', data, {})

    dispatch({
      type: actions.set_persons_detail,
      data: [json.data],
    })

    return json.data
  })
}

export const fetchPersonPFSAndSREO = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    try {
      const { data: pfs } = await Api.get(`/persons/${id}/pfs`, null, {})
      dispatch({
        type: actions.set_persons_pfs,
        data: [pfs],
      })
    } catch (e) {}
    try {
      const { data: sreo } = await Api.get(`/persons/${id}/sreo`, null, {})
      dispatch({
        type: actions.set_persons_sreo,
        data: [sreo],
      })
    } catch (e) {}
  })
}

export const updatePersonPFS = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.patch(`/persons/${id}/pfs`, data, {})
    // await Api.get(`/persons/${id}/export-pfs?download=false`)

    dispatch({
      type: actions.set_persons_pfs,
      data: [json.data],
    })
  })
}

export const updatePersonSREO = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.patch(`/persons/${id}/sreo`, data, {})
    // await Api.get(`/persons/${id}/export-pfs?download=false`)

    dispatch({
      type: actions.set_persons_sreo,
      data: [json.data],
    })
  })
}

export const fetchPersonFlow = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get(`/persons/${id}/flow`, null, {}).paginate(
      `persons_flow-${id}`,
      dispatch,
      getState,
    )

    dispatch({
      type: actions.set_flows,
      data: json.data,
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
