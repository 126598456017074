/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import './index.scss'
import { clsx } from 'src/util'
import { useLockScroll } from 'src/hooks/use-lock-body-scroll'

export const Popover = ({
  element,
  className,
  children,
  content,
  onClose,
  cover,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)
  const styleRef = useRef(null)
  useEffect(() => {
    window.addEventListener('mousedown', close)
    return () => window.removeEventListener('mousedown', close)
  }, [])
  const position = ref.current && ref.current.getBoundingClientRect()
  if (position) {
    const isNearTop = position && position.y < window.innerHeight / 2
    const isNearLeft = position && position.x < window.innerWidth / 2
    const containerHeight = position ? position.height : 32
    styleRef.current = {
      top: isNearTop
        ? position.y + (cover ? 0 : containerHeight + 2)
        : 'initial',
      bottom: isNearTop ? 'initial' : window.innerHeight - position.y + 2,
      right: isNearLeft
        ? 'initial'
        : window.innerWidth - position.x - position.width,
      left: isNearLeft ? position.x : 'initial',
      minWidth: position.width,
      maxHeight:
        (isNearTop ? window.innerHeight - position.y : position.y) -
        containerHeight -
        14,
    }
  }

  const close = useCallback((e) => {
    e.persist?.()
    e.stopPropagation()
    setOpen(false)
    if (onClose) onClose(e)
  }, [])

  const Element = element || 'div'
  return (
    <Element
      className={clsx('popover-trigger', className)}
      onClick={(e) => setOpen(true)}
      ref={ref}
      {...props}
    >
      {children}
      {open && (
        <PopoverContainer style={styleRef.current}>
          {typeof content === 'object' ? content : content({ close })}
        </PopoverContainer>
      )}
    </Element>
  )
}

Popover.defaultProps = {
  className: '',
  content: () => null,
  cover: false,
}

Popover.propTypes = {
  element: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
  cover: PropTypes.bool,
}

const PopoverContainer = ({ children, style }) => {
  useLockScroll(document.querySelector('.dealinfo.open'))

  return ReactDOM.createPortal(
    <div
      className='popover'
      style={style}
      onMouseDown={(e) => {
        e.persist?.()
        e.stopPropagation()
      }}
    >
      {children}
    </div>,
    document.querySelector('body'),
  )
}

PopoverContainer.propTypes = {
  children: PropTypes.element.isRequired,
  style: PropTypes.object.isRequired,
}
