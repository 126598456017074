import React from 'react'
import { AuthContext } from './index'

export default function (mapFn) {
  return function (ReactComponent) {
    return function MSALConnectFunction (componentProps) {
      return (
        <AuthContext.Consumer>
          {({ store }) => {
            const { dispatch, getState } = store
            const props =
              mapFn && store ? mapFn(getState(), componentProps) : {}
            return (
              <ReactComponent
                {...componentProps}
                {...props}
                msdispatch={dispatch}
              />
            )
          }}
        </AuthContext.Consumer>
      )
    }
  }
}
