import actions from 'src/actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.set_users: {
      if (!data) return state
      const obj = {}
      for (const u of data) {
        obj[u.id] = {
          ...state[u.id],
          ...u,
        }
      }
      return {
        ...state,
        ...obj,
      }
    }
    default:
      return state
  }
}

export default reduce
