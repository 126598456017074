import actions from 'src/actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.set_conversation_messages:
      return {
        ...state,
        [data.conversationID]: data.value,
      }
    default:
      return state
  }
}

export default reduce
