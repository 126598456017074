import React, { useState, useEffect } from 'react'

export const ConfirmButton = ({ className, ...props }) => {
  const [pending, setPending] = useState(false)
  useEffect(() => {
    let timer
    if (pending) {
      timer = setTimeout(() => {
        setPending(false)
      }, 3000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [pending])

  const onClick = (e) => {
    if (pending) {
      props.onClick(e)
      setPending(false)
    } else {
      setPending(true)
    }
  }
  return (
    <button
      className={'btn btn-confirm' + (className ? ' ' + className : '')}
      disabled={props.disabled}
      onClick={onClick}
    >
      {pending ? props.confirm || 'Confirm?' : props.children || props.text}
    </button>
  )
}
