import actions from 'src/actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.set_attachments:
      return {
        ...state,
        [data.messageID]: data.value,
      }
    case actions.remove_attachment:
      return {
        ...state,
        [data.messageID]: state[data.messageID].filter(
          (x) => x.id !== data.attachmentID,
        ),
      }
    default:
      return state
  }
}

export default reduce
