import { workstate, setRelatedItems } from './index'
import { Api } from 'src/util'

const _actions = []
const actions = {}

export const bulkUpdate = (resource, ids, data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.patch(`/bulk/${resource}`, {
      ids: ids.join(','),
      data,
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const bulkDelete = (resource, ids) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    await Api.delete(`/bulk/${resource}?ids=${ids.join(',')}`)

    switch (resource) {
      case 'activities':
        for (const id of ids) {
          dispatch({
            type: 'delete_activity',
            data: id,
          })
        }
        break
      default:
    }
  })
}

export const reorderItems = (resource, ids) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.patch(`/reorder/${resource}`, {
      ids,
    })

    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
