import React from 'react'
import { withScope, captureException } from '@sentry/browser'
import PropTypes from 'prop-types'
import './index.scss'

export default class ErrorBoundary extends React.Component {
  state = {
    error: null,
    info: '',
    eventId: null,
  }

  componentDidCatch (e, info) {
    console.warn(e)
    this.setState({
      error: e.message || e,
      info,
    })
    if (process.env.NODE_ENV !== 'development') {
      withScope((scope) => {
        scope.setExtras(info)
        const eventId = captureException(e)
        this.setState({ eventId })
      })
    }
  }

  render () {
    if (this.state.error) {
      const { name } = this.props
      const { error, info, eventId } = this.state
      if (process.env.NODE_ENV !== 'development') {
        return (
          <div className='error-boundary flex flex-container flex-vertical'>
            <h1>
              {error}
              {`${name ? ` (${name})` : ''}`}
            </h1>
            <h2>Please send this to the developers.</h2>
            {eventId && <p>Event ID: {this.state.eventId}</p>}
          </div>
        )
      } else {
        return (
          <div className='error-boundary flex flex-container flex-vertical'>
            <h1>
              {error}
              {`${name ? ` (${name})` : ''}`}
            </h1>
            <div className='scroll flex'>
              <pre>{info.componentStack.replace(/^\s*\n/, '')}</pre>
            </div>
          </div>
        )
      }
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  name: PropTypes.string,
}
