// import demoDealFieldsData from '../util/demo-dealfields'
// import demoPersonsData from '../util/demo-dealpersons'
import { workstate } from './index'
import { Api } from 'src/util'

const _actions = ['delete_flow', 'update_flow', 'set_flows']
const actions = {}

export const createFlow = (data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.post('/flows', data, {})

    dispatch({
      type: actions.set_flows,
      data: [json.data],
    })
  })
}

export const updateFlow = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.patch(`/flows/${id}`, data, {})

    dispatch({
      type: actions.update_flow,
      data: {
        key: id,
        value: json.data,
      },
    })
  })
}

export const deleteFlow = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    await Api.delete(`/flows/${id}`, null, {})

    dispatch({
      type: actions.delete_flow,
      data: id,
    })
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
