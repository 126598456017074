import {
  mdiAccountMultiple,
  mdiClockOutline,
  mdiPhone,
  mdiEmail,
  mdiFlag,
  mdiMessageProcessing,
} from '@mdi/js'

const Constants = {
  APP_NAME: 'FM|OS',
  FIELD_CATEGORIES: [
    {
      name: 'Property Information',
      key: 'property',
    },
    {
      name: 'Investment Overview',
      key: 'investment',
    },
    {
      name: 'Loan Overview',
      key: 'loan',
    },
    {
      name: 'None',
      key: '',
    },
  ],
  FIELD_TYPES: [
    {
      name: 'Number',
      key: 'int',
      description: 'Used to store numeric data.',
    },
    {
      name: 'Text',
      key: 'varchar',
      description: 'Used to store texts up to 255 characters.',
    },
    {
      name: 'Boolean',
      key: 'boolean',
      description: 'A true/false value.',
    },
    {
      name: 'Monetary',
      key: 'monetary',
      description: 'Used to store monetary data.',
    },
    {
      name: 'Percent',
      key: 'percent',
      description: 'Used to store percentages.',
    },
    {
      name: 'Numerical',
      key: 'numerical',
      description:
        'Used to store a numerical value presented as a number, percent, or product.',
    },
    {
      name: 'Person',
      key: 'people',
      description: 'Contains one Person from your Contacts.',
    },
    {
      name: 'Date',
      key: 'date',
      description: 'Used to store a date.',
    },
    {
      name: 'Organization',
      key: 'org',
      description: 'Contains one Organization from your Contacts.',
    },
    {
      name: 'Address',
      key: 'address',
      description: "Used to store a location's full address.",
    },
    {
      name: 'Phone',
      key: 'phone',
      description: 'Used to store phone numbers.',
    },
    {
      name: 'Email',
      key: 'email',
      description: 'Used to store email addresses.',
    },
    {
      name: 'Set',
      key: 'set',
      description: 'Lets you define a list of values to choose from.',
    },
    {
      name: 'User',
      key: 'user',
      description: 'Contains a single User.',
    },
    {
      name: 'Pipeline stage',
      key: 'stage',
      description: 'A stage in a pipeline.',
    },
    {
      name: 'Visible to',
      key: 'visible_to',
      description: 'Who this item is visible to.',
    },
    {
      name: 'Text with options',
      key: 'varchar_options',
      description: 'Used to store texts up to 255 characters.',
    },
    {
      name: 'Formula',
      key: 'formula',
      description: 'A formula for calculating a field from other fields.',
    },
    {
      name: 'Status',
      key: 'status',
      description: 'The status of this item.',
    },
    {
      name: 'Enum',
      key: 'enum',
      description: 'A choice from a list of predefined values.',
    },
    {
      name: 'Deal template',
      key: 'deal_template',
      description: 'A deal template.',
    },
    {
      name: 'Social security',
      key: 'ssn',
      description: 'A social security number.',
    },
    {
      name: 'Unit type',
      key: 'unit_type',
      description: 'A residential unit type.',
    },
  ],
  EMAIL_MESSAGES_PER_PAGE: 30,
  // PAGINATION_PER_PAGE: 5,
  LOCALSTORAGE_KEY: 'commercialsite',
  EVENT_TYPES: [
    {
      name: 'Call',
      type: 'call',
      icon: mdiPhone,
    },
    {
      name: 'Meeting',
      type: 'meeting',
      icon: mdiAccountMultiple,
    },
    {
      name: 'Task',
      type: 'task',
      icon: mdiClockOutline,
    },
    {
      name: 'Text',
      type: 'text',
      icon: mdiMessageProcessing,
    },
    {
      name: 'Deadline',
      type: 'deadline',
      icon: mdiFlag,
    },
    {
      name: 'Email',
      type: 'email',
      icon: mdiEmail,
    },
  ],
  GUID_REG:
    /(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/,
  Colors: {
    Green: '#2da322',
    Red: '#f94839',
    Yellow: '#fcf65f',
    Accent: '#a5021b',
    Accent2: '#730113',
  },
  PERMISSIONS: [
    {
      key: 'administrator',
      name: 'Administrator',
      description: 'Users with this permission will have every permission.',
    },
    {
      key: 'pipelines.manage',
      name: 'Manage pipelines',
      description:
        'Users with this permission can create, edit, and delete pipelines.',
    },
    {
      key: 'deals.create',
      name: 'Create deals',
    },
    {
      key: 'deals.edit',
      name: 'Edit deals',
      description: 'Users with this permission can edit and close their deals.',
      condition: (user, deal) => user.id === deal.user_id,
    },
    {
      key: 'deals.manage',
      name: 'Manage deals',
      description:
        'Users with this permission can create, edit, and delete all deals.',
    },
    {
      key: 'activities.create',
      name: 'Create activities',
      description: 'Users with this permission can create activities.',
    },
    {
      key: 'activities.edit',
      name: 'Edit activities',
      description:
        'Users with this permission can edit and delete their activities.',
      condition: (user, activity) => user.id === activity.user_id,
    },
    {
      key: 'activities.manage',
      name: 'Manage activities',
      description:
        'Users with this permission can edit and delete all activities.',
    },
    {
      key: 'contacts.edit',
      name: 'Edit persons',
      description:
        'Users with this permission can create and edit people and organizations.',
    },
    {
      key: 'contacts.manage',
      name: 'Manage persons',
      description:
        'Users with this permission can create, edit, and remove people and organizations.',
    },
    {
      key: 'statistics.view',
      name: 'View progress reports',
    },
    {
      key: 'fields.manage',
      name: 'Manage custom fields and templates',
      description:
        'Users with this permission can create, edit, and delete custom fields and templates.',
    },
  ],
}

export default Constants
