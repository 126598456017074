/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import ErrorBoundary from 'src/ErrorBoundary'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { clsx } from 'src/util'

const Step = () => <div />

export const Steps = ({
  children,
  onSave,
  onReset,
  onStep,
  beforeStep,
  onCancel,
  step,
  canStep,
  bodyTemplate,
  footerTemplate,
  preventSkipping,
  finishText,
}) => {
  const [activeChild, setActiveChild] = useState(step || 0)
  const [maxChild, setMaxChild] = useState(activeChild)
  useEffect(() => {
    if (activeChild > maxChild) {
      setMaxChild(activeChild)
    }
  }, [activeChild, maxChild])

  useEffect(() => {
    if (step !== activeChild) setActiveChild(step)
  }, [step])

  const updateActiveChild = (i, required) => {
    if (i > activeChild && !beforeStep(i)) return
    onStep(i)
    setActiveChild(i)
  }

  const doReset = () => {
    onReset()
    setMaxChild(0)
    setActiveChild(0)
  }

  const items = (children instanceof Array ? children : [children]).filter(
    (x) => x
  )
  const headers = items.map((x) => ({
    title: x.props.title,
    required: x.props.required,
  }))

  return (
    <div className='steps'>
      <header>
        {headers.map(({ title, required }, i, a) => {
          const prevHeader = a[maxChild]
          return (
            <div
              className={clsx(
                'step-title',
                activeChild >= i && 'active',
                i > maxChild &&
                  (preventSkipping || prevHeader?.required) &&
                  'disabled'
              )}
              key={i}
              onClick={(e) => {
                if (
                  !(i > maxChild && (preventSkipping || prevHeader?.required))
                ) { updateActiveChild(i) }
              }}
            >
              {title} {required ? '*' : ''}
            </div>
          )
        })}
      </header>
      {bodyTemplate(
        <SwitchTransition>
          <CSSTransition
            key={activeChild}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false)
            }}
            classNames='slide'
            appear
          >
            <main className='slide'>
              <div className='step flex-container flex-vertical'>
                <ErrorBoundary name='Step'>
                  {items[activeChild]
                    ? items[activeChild].props.children
                    : null}
                </ErrorBoundary>
              </div>
            </main>
          </CSSTransition>
        </SwitchTransition>,
        step
      )}
      <footer
        className={
          'flex-container' +
          (items[activeChild]?.props.hideFooter ? ' hidden' : '')
        }
      >
        {footerTemplate(
          <button
            className='btn delete'
            onClick={doReset}
            style={{ marginRight: 10 }}
          >
            Reset
          </button>,
          <>
            {activeChild > 0 ? (
              <button
                className='btn btn-clear'
                onClick={(e) => updateActiveChild(activeChild - 1)}
              >
                Back
              </button>
            ) : (
              <button className='btn btn-clear' onClick={onCancel}>
                Cancel
              </button>
            )}
            {activeChild === items.length - 1 ? (
              <button
                className='btn primary'
                onClick={onSave}
                disabled={!canStep}
              >
                {finishText}
              </button>
            ) : (
              <button
                className='btn primary'
                onClick={(e) => updateActiveChild(activeChild + 1)}
                disabled={!canStep}
              >
                Continue
              </button>
            )}
          </>
        )}
      </footer>
    </div>
  )
}

Step.defaultProps = {
  bodyTemplate: (body) => body,
  footerTemplate: (resetButton, controls) => (
    <>
      {resetButton}
      <span className='flex' />
      {controls}
    </>
  ),
  canStep: true,
  required: false,
}

Steps.defaultProps = {
  preventSkipping: false,
  finishText: 'Finish',
}

Step.propTypes = {
  title: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onSave: PropTypes.func,
  onStep: PropTypes.func,
  onReset: PropTypes.func,
  onCancel: PropTypes.func,
  beforeStep: PropTypes.func,
  step: PropTypes.number,
  bodyTemplate: PropTypes.func,
  footerTemplate: PropTypes.func,
}

Steps.propTypes = {
  preventSkipping: PropTypes.bool,
  children: PropTypes.array,
  onSave: PropTypes.func,
  onReset: PropTypes.func,
  onStep: PropTypes.func,
  beforeStep: PropTypes.func,
  onCancel: PropTypes.func,
  step: PropTypes.number,
  canStep: PropTypes.bool,
  bodyTemplate: PropTypes.func,
  footerTemplate: PropTypes.func,
  finishText: PropTypes.string,
}

export { Step }
