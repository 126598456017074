import actions from '../actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  let obj = {}
  switch (type) {
    case actions.set_organizations:
      if (!data) return state
      for (const org of data) {
        obj[org.id] = {
          ...state[org.id],
          ...org,
        }
      }
      return {
        ...state,
        ...obj,
      }
    case actions.set_organizations_detail:
      if (!data) return state
      for (const org of data) {
        obj[org.id] = {
          ...state[org.id],
          ...org,
          fully_loaded: true,
        }
      }
      return {
        ...state,
        ...obj,
      }
    case actions.remove_organization:
      obj = { ...state }
      delete obj[data]
      return {
        ...obj,
      }
    default:
      return state
  }
}

export default reduce
