import actions from '../actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  let obj = {}
  switch (type) {
    case actions.set_persons:
      if (!data) return state
      for (const person of data) {
        obj[person.id] = {
          ...state[person.id],
          ...person,
        }
      }
      return {
        ...state,
        ...obj,
      }
    case actions.set_persons_detail:
      if (!data) return state
      for (const person of data) {
        obj[person.id] = {
          ...state[person.id],
          ...person,
          fully_loaded: true,
        }
      }
      return {
        ...state,
        ...obj,
      }
    case actions.remove_person:
      obj = { ...state }
      delete obj[data]
      return obj
    default:
      return state
  }
}

export default reduce
