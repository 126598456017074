import React, { useState, useEffect } from 'react'
import Icon from '@mdi/react'
import { Tab } from './Tab'
import './index.scss'
import { NavLink } from 'react-router-dom'

export const Tabs = ({ children, active }) => {
  const [activeTab, setActiveTab] = useState(active || 0)

  useEffect(() => {
    setActiveTab(active)
  }, [active])

  let tabs = children || []
  if (!(tabs instanceof Array)) tabs = [tabs]
  const tab = tabs[activeTab]

  return (
    <div className='tabs flex flex-container flex-vertical'>
      <nav className='tabs-header'>
        {tabs.map((tab, i) => {
          if (tab.props.to) {
            return (
              <NavLink
                to={tab.props.to}
                key={i}
                className={'tab' + (i === activeTab ? ' active' : '')}
                onClick={(e) => setActiveTab(i)}
                isActive={() => activeTab === i}
              >
                {tab.props.icon && <Icon path={tab.props.icon} />}
                <span>{tab.props.title}</span>
              </NavLink>
            )
          } else {
            return (
              <div
                key={i}
                className={'tab' + (i === activeTab ? ' active' : '')}
                onClick={(e) => setActiveTab(i)}
              >
                {tab.props.icon && <Icon path={tab.props.icon} />}
                <span>{tab.props.title}</span>
              </div>
            )
          }
        })}
      </nav>
      {tab && (
        <div className='tabs-content flex flex-container'>
          {tab.props.children}
        </div>
      )}
    </div>
  )
}

export { Tab }
