import actions from 'src/actions'

const defaultState = []

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.replace_search:
      return data
    case actions.append_search:
      return state.concat(data)
    default:
      return state
  }
}

export default reduce
