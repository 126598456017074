import React, { useState } from 'react'
import PropTypes from 'prop-types'

const texts = {
  confirm: {
    cancel: 'Cancel',
    submit: 'Continue',
  },
  alert: {
    submit: 'OK',
  },
  feedback: {
    cancel: 'Cancel',
    submit: 'Submit',
  },
}

const ConfirmationDialog = ({
  title,
  variant,
  description,
  placeholder,
  onSubmit,
  onClose,
  reverse,
}) => {
  const [content, setContent] = useState('')
  const text = texts[variant]
  const submitProps =
    text.cancel && !reverse
      ? {
        className: 'btn primary',
      }
      : {
        className: 'btn btn-clear',
      }
  const cancelProps = reverse
    ? {
      className: 'btn primary',
    }
    : {
      className: 'btn btn-clear',
    }

  return (
    <>
      {title && (
        <header>
          <h1>
            <span className='title'>{title}</span>
          </h1>
        </header>
      )}
      {(description || variant === 'feedback') && (
        <main className='flex-container flex-vertical'>
          {description && <p style={{ margin: 0 }}>{description}</p>}
          {variant === 'feedback' && (
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder={placeholder}
            />
          )}
        </main>
      )}
      <footer className='flex-container' style={{ padding: 0 }}>
        <span className='flex' />
        {text.cancel && (
          <button {...cancelProps} onClick={onClose} autoFocus>
            {text.cancel}
          </button>
        )}
        {text.submit && (
          <button
            {...submitProps}
            onClick={() => onSubmit(content || true)}
            disabled={variant === 'feedback' && !content}
          >
            {text.submit}
          </button>
        )}
      </footer>
    </>
  )
}

ConfirmationDialog.defaultProps = {
  open: false,
  onSubmit: () => null,
  onClose: () => null,
  variant: 'confirm',
  placeholder: 'Leave some feedback',
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['confirm', 'alert', 'feedback']),
  placeholder: PropTypes.string,
}

export default ConfirmationDialog
