import { jsx } from 'slate-hyperscript'

const ELEMENT_TAGS = {
  A: (el) => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: (el) => ({ type: 'image', url: el.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
  DIV: () => ({ type: 'block' }),

  // because microsoft is bad and put text elements inside these text elements and Slate doesnt like that
  CODE: () => ({ type: 'code' }),
  DEL: () => ({ type: 'strikethrough' }),
  EM: () => ({ type: 'italic' }),
  I: () => ({ type: 'italic' }),
  S: () => ({ type: 'strikethrough' }),
  B: () => ({ type: 'bold' }),
  STRONG: () => ({ type: 'bold' }),
  U: () => ({ type: 'underline' }),
}

const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  B: () => ({ bold: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
}

export const _deserializeHtml = (el) => {
  if (el.nodeType === 3) {
    return /^(\r?\n)+$/.test(el.textContent) ? null : el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  } else if (el.nodeName === 'HR') {
    return jsx('element', { type: 'block' }, [jsx('text', {}, '—————')])
  }

  const { nodeName } = el
  let parent = el

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0]
  }

  const children = Array.from(parent.childNodes).map(_deserializeHtml).flat()
  if (!children.length) return null

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el)
    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map((child) => {
      if (!child) return null
      if (child.type) {
        const elAttrs = ELEMENT_TAGS[el.nodeName](el)
        return jsx('element', elAttrs, [_deserializeHtml(child)])
      } else {
        return jsx('text', attrs, child)
      }
    })
  }

  return children
}

export const deserializeHtml = (str) => {
  const doc = new DOMParser().parseFromString(str, 'text/html')
  // console.log(doc)
  return _deserializeHtml(doc.body)
}
