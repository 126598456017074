/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'src/components/Select'
import { ContextMenuContext } from './Provider'

export const ContextMenu = ({ children, ...props }) => {
  const [position, setPosition] = useState(null)
  const { shift } = useContext(ContextMenuContext)
  const styleRef = useRef(null)

  const closeMenu = (e) => {
    setPosition(null)
    window.removeEventListener('click', closeMenu)
  }

  const drop = useMemo(
    () => (
      <Dropdown
        key='ctx-drop'
        open={Boolean(position)}
        setOpen={closeMenu}
        styleRef={styleRef}
        position={position}
        {...props}
      />
    ),
    [position, props]
  )

  const els = useMemo(() => {
    const ca = React.Children.toArray(children)[0]

    const childChildren = React.Children.toArray(ca.props.children).map(
      (c, i) =>
        React.isValidElement(c)
          ? React.cloneElement(c, {
            key: i,
          })
          : c
    )

    return React.cloneElement(ca, {
      onContextMenu: (e) => {
        if (shift || !props.items?.length) return
        e.preventDefault()
        setPosition({
          x: Math.min(Math.max(e.pageX - 120, 10), window.innerWidth - 10),
          y: e.pageY - 16,
        })
        window.addEventListener('click', closeMenu)
      },
      children: [...childChildren, drop],
    })
  }, [children, drop])

  useEffect(() => {
    return () => window.removeEventListener('click', closeMenu)
  }, [children])

  return els
}

ContextMenu.propTypes = {
  children: PropTypes.element,
}
