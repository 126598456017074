import React from 'react'
import { useSlate } from 'slate-react'
import Icon from '@mdi/react'
import { Editor, Transforms, Range } from 'slate'
import { mdiLinkPlus } from '@mdi/js'

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  })
  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => n.type === 'link',
  })
  return !!link
}

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const insertLink = (editor, url, text) => {
  wrapLink(editor, url, text)
}

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => n.type === 'link',
  })
}

export const wrapLink = (editor, url, text) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }
  const { selection } = editor
  const isCollapsed = !selection || (selection && Range.isCollapsed(selection))
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: text || url }] : [],
  }
  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const Button = ({ active, children, ...props }) => {
  return (
    <button
      className={
        'btn btn-square btn-clear no-shadow' + (active ? ' active' : '')
      }
      {...props}
    >
      {children}
    </button>
  )
}

export const BlockButton = ({ format, path }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(e) => {
        e.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon path={path} />
    </Button>
  )
}

export const MarkButton = ({ format, path }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(e) => {
        e.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon path={path} />
    </Button>
  )
}

export const LinkButton = ({ openModal }) => {
  const editor = useSlate()
  const v = { value: '' }
  const { selection } = editor
  if (!selection || (selection && Range.isCollapsed(selection))) v.text = ''
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(e) => {
        e.preventDefault()
        if (openModal) openModal(v)
      }}
    >
      <Icon path={mdiLinkPlus} />
    </Button>
  )
}
