import { UserAgentApplication } from 'msal'
import GraphClient from './graph-client'
import { setWorking, setData } from './actions'
import { history } from 'src/history'

class Store {
  constructor (config) {
    this.needsDispatch = true
    this.config = config
    this.userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: config.appID,
        redirectUri: config.redirectURI,
        authority: config.authority,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    })
    for (const fn of ['login', 'logout', 'getUserProfile', 'refreshToken']) {
      this[fn] = this[fn].bind(this)
    }
  }

  handleError (e) {
    let error = {}
    if (typeof e === 'string') {
      const parts = e.split('|')
      error =
        parts.length > 1
          ? { message: parts[1], hint: parts[0] }
          : { message: e }
    } else {
      error = { message: e.message, hint: JSON.stringify(e) }
    }
    this._dispatch(
      setData({
        isAuthenticated: false,
        user: null,
        error,
        working: false,
      })
    )
  }

  async getToken () {
    console.info('trying to get msal token...')
    try {
      return await this.userAgentApplication.acquireTokenSilent({
        scopes: this.config.scopes,
      })
    } catch (e) {
      console.warn(e)
      if (
        (e.indexOf
          ? e.indexOf('interaction_required')
          : e.message.indexOf('interaction_required')) > -1
      ) {
        try {
          return await this.userAgentApplication.acquireTokenPopup({
            scopes: this.config.scopes,
          })
        } catch (e) {
          this.handleError(e)
        }
      } else this.handleError(e)
    }
  }

  /**
   * @param {function(action)} dispatchFunction
   */
  // eslint-disable-next-line accessor-pairs
  set dispatch (dispatchFunction) {
    // this is only set once when the store is created, and is a hack to make the state update
    if (!this.needsDispatch) return
    delete this.needsDispatch
    this._dispatch = dispatchFunction
    this._dispatch(setWorking(true))
    const user = this.userAgentApplication.getAccount()
    this._dispatch(
      setData({
        isAuthenticated: user !== null,
        user,
        error: null,
      })
    )

    if (user) {
      this.getUserProfile()
    } else this._dispatch(setWorking(false))
  }

  async login () {
    try {
      this._dispatch(setWorking(true))
      await this.userAgentApplication.loginPopup({
        scopes: this.config.scopes,
        prompt: 'select_account',
      })
      /* if (history.location.pathname === '/') {
        history.replace('/dashboard')
      } */
      await this.getUserProfile()
    } catch (e) {
      this.handleError(e)
    }
  }

  async logout () {
    this.userAgentApplication.logout()
  }

  async getUserProfile () {
    try {
      const token = await this.getToken()
      if (token) {
        const user = await GraphClient.getUserDetails(token)
        this._dispatch(
          setData({
            isAuthenticated: true,
            user,
            error: null,
            token,
          })
        )
      }
    } catch (e) {
      this.handleError(e)
    } finally {
      this._dispatch(setWorking(false))
    }
  }

  async refreshToken () {
    try {
      const token = await this.getToken()
      if (token) {
        this._dispatch(
          setData({
            isAuthenticated: true,
            error: null,
            token,
          })
        )
      }
    } catch (e) {
      this.handleError(e)
    } finally {
      this._dispatch(setWorking(false))
    }
  }
}

const createStore = (config) => {
  return new Store(config)
}

export { createStore }
