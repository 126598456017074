import { workstate, setRelatedItems } from './index'
import { Api } from 'src/util'

const _actions = ['set_roles', 'delete_role']
const actions = {}

export const fetchRoles = (id) => (dispatch, getState) => {
  return workstate(dispatch, 'fetch-roles', async () => {
    let json
    if (id) {
      json = await Api.get(`/roles/${id}`, null, {})
      dispatch({
        type: actions.set_roles,
        data: [json.data],
      })
    } else {
      json = await Api.get('/roles', null, {}).paginate(
        'team',
        dispatch,
        getState,
      )
      dispatch({
        type: actions.set_roles,
        data: json.data,
      })
    }
  })
}

export const createRole = (data) => (dispatch, getState) => {
  return workstate(dispatch, 'create-role', async () => {
    const json = await Api.post('/roles', data, {})

    dispatch({
      type: actions.set_roles,
      data: [json.data],
    })

    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const updateRole = (id, data) => (dispatch, getState) => {
  const d = { ...data }
  delete d.id
  delete d.created_at
  delete d.updated_at
  return workstate(dispatch, 'update-role', async () => {
    const json = await Api.patch(`/roles/${id}`, d, {})

    dispatch({
      type: actions.set_roles,
      data: [json.data],
    })

    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const deleteRole = (id) => (dispatch, getState) => {
  return workstate(dispatch, 'delete-role', async () => {
    const json = await Api.delete(`/roles/${id}`, null, {})

    dispatch({
      type: actions.delete_role,
      data: id,
    })

    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
