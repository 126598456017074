import React, { useEffect, useState } from 'react'
import './index.scss'

export const ContextMenuContext = React.createContext()

const ContextMenuProvider = ({ children }) => {
  const [shift, setShift] = useState(false)

  const setShiftDown = (e) => {
    if (e.key === 'Shift') {
      setShift(true)
    }
  }
  const setShiftUp = (e) => {
    if (e.key === 'Shift') {
      setShift(false)
    }
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      window.addEventListener('keydown', setShiftDown)
      window.addEventListener('keyup', setShiftUp)
      return () => {
        window.removeEventListener('keydown', setShiftDown)
        window.removeEventListener('keyup', setShiftUp)
      }
    }
  }, [])

  return (
    <ContextMenuContext.Provider value={{ shift }}>
      {children}
    </ContextMenuContext.Provider>
  )
}

export default ContextMenuProvider
