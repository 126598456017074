import React, { useState } from 'react'
import {
  FlexibleXYPlot,
  HorizontalBarSeries,
  VerticalBarSeries,
  YAxis,
  XAxis,
  VerticalGridLines,
  HorizontalGridLines,
  Hint,
  DiscreteColorLegend,
} from 'react-vis'
import PropTypes from 'prop-types'
import 'react-vis/dist/style.css'
import { Format } from 'src/util'

export const BarChart = ({
  data,
  keys,
  colors,
  legends,
  indexBy,
  labelTop,
  labelBottom,
  labelLeft,
  labelRight,
  grids,
  tooltip,
  direction,
  stack,
  animate,
  interactive,
  showAverageLine,
  showTotals,
}) => {
  const [tooltipValues, setTooltipValues] = useState(null)

  if (!legends) legends = keys.reduce((a, x) => ({ ...a, [x]: x }), {})
  const keysOfData = data.reduce((set, x) => {
    Object.keys(x).forEach((k) => set.add(k))
    return set
  }, new Set())
  data = data.map((x) => {
    for (const k of keysOfData) {
      if (!{}.hasOwnProperty.apply(x, [k])) x[k] = 0
    }
    return x
  })
  // console.log(data)

  const BarSeries =
    direction === 'row' ? HorizontalBarSeries : VerticalBarSeries

  const clusters = []
  const total = {}
  data.forEach((x) => {
    Object.keys(x).forEach((k) => {
      if (k !== indexBy) {
        if (!total[x[indexBy]]) total[x[indexBy]] = 0
        total[x[indexBy]] += x[k]
      }
    })
  })
  for (const index in keys) {
    const key = keys[index]
    const sectionData = data.map((x, i) => {
      const color = colors[key]
      const value = x[key]
      const label = x[indexBy]
      let res
      if (direction === 'row') {
        res = { y: i, x: value, total: total[label], label, name: key, color }
      } else {
        res = { y: value, x: i, total: total[label], label, name: key, color }
      }
      return res
    })
    clusters.push({
      data: handleSingleBar(sectionData),
      cluster: stack ? 'cluster' : index,
    })
  }

  const setTooltip = (value) => {
    if (
      !tooltipValues ||
      tooltipValues.x !== value.x ||
      tooltipValues.y !== value.y
    ) {
      const otherValues = clusters.map((x) =>
        x.data.find((y) => y.label === value.label)
      )
      setTooltipValues({
        ...value,
        values: otherValues,
      })
    }
  }

  const getDomainMax = (clusters, key) => {
    let max = 0
    if (!clusters.length) return 0
    const d = clusters[0].data
    for (const i in d) {
      let m = 0
      if (stack) m = clusters.reduce((a, { data }) => a + data[i][key], 0)
      else m = Math.max(...clusters.map((x) => x.data[i][key]).flat())
      if (m > max) max = m
    }
    return max
  }
  function handleSingleBar (data) {
    if (data.length === 1) {
      const item = data[0]
      if (direction === 'row') {
        return [item, { x: 0, y: 2 }]
      } else {
        return [item, { x: 2, y: 0 }]
      }
    } else return data
  }
  const xDomainMax = Math.max(
    direction === 'column'
      ? Math.max(2, data.length)
      : getDomainMax(clusters, 'x'),
    1
  )
  const yDomainMax = Math.max(
    direction === 'row'
      ? Math.max(2, data.length)
      : getDomainMax(clusters, 'x'),
    1
  )
  const maxTicks = (ticks) => Math.max(1, Math.min(ticks, 10))

  // console.log(getDomainMax(clusters, 'y'), getDomainMax(clusters, 'x'), xDomainMax, yDomainMax)
  // console.log(xDomainMax, yDomainMax, maxTicks(xDomainMax), maxTicks(yDomainMax))

  console.log(clusters)
  const tooltipAlign = {
    horizontal: direction === 'row' ? 'right' : 'auto',
    vertical: direction === 'column' ? 'top' : 'auto',
  }

  return (
    <div className='chart chart-bar flex flex-container'>
      <div className='chart-container flex'>
        <FlexibleXYPlot
          stackBy={stack ? (direction === 'column' ? 'y' : 'x') : undefined}
          animation={animate ? 'stiff' : null}
          dontCheckIfEmpty={true}
          onMouseLeave={(e) => setTooltipValues(null)}
          xDomain={[0, xDomainMax]}
          yDomain={[0, yDomainMax]}
          margin={{ left: 140, right: 80, top: 40, bottom: 40 }}
          colorType='literal'
        >
          {direction === 'row' && Object.keys(grids).length === 0 ? (
            <VerticalGridLines tickTotal={maxTicks(xDomainMax)} />
          ) : (
            <HorizontalGridLines tickTotal={maxTicks(yDomainMax)} />
          )}
          {Object.keys(grids).length > 0 && (
            <>
              {grids.x && (
                <HorizontalGridLines tickTotal={maxTicks(yDomainMax)} />
              )}
              {grids.y && (
                <VerticalGridLines tickTotal={maxTicks(xDomainMax)} />
              )}
            </>
          )}

          {clusters.map((clusterProps, i) => {
            const extraProps = {
              onValueMouseOver: interactive ? setTooltip : undefined,
            }
            return (
              <BarSeries
                key={i}
                {...clusterProps}
                {...extraProps}
                barWidth={0.5}
              />
            )
          })}

          {labelLeft && (
            <YAxis
              attrAxis='x'
              orientation='left'
              position='end'
              tickTotal={maxTicks(yDomainMax)}
              tickFormat={
                labelLeft.attr
                  ? (v, i) => {
                    if (data[i]) return data[i][indexBy]
                  }
                  : null
              }
              {...labelLeft}
              attr='y'
            />
          )}
          {labelRight && (
            <YAxis
              attrAxis='x'
              orientation='right'
              position='end'
              tickTotal={maxTicks(yDomainMax)}
              tickFormat={
                labelRight.attr
                  ? (v, i) => {
                    if (data[i]) return data[i][indexBy]
                  }
                  : null
              }
              {...labelRight}
              attr='y'
            />
          )}
          {labelTop && (
            <XAxis
              attrAxis='y'
              orientation='top'
              position='end'
              tickTotal={maxTicks(xDomainMax)}
              tickFormat={
                labelTop.attr
                  ? (v, i) => {
                    if (data[i]) return data[i][indexBy]
                  }
                  : null
              }
              {...labelTop}
              attr='x'
            />
          )}
          {labelBottom && (
            <XAxis
              attrAxis='y'
              orientation='bottom'
              position='end'
              tickTotal={maxTicks(xDomainMax)}
              tickFormat={
                labelBottom.attr
                  ? (v, i) => {
                    if (data[i]) return data[i][indexBy]
                  }
                  : null
              }
              {...labelBottom}
              attr='x'
            />
          )}

          {/* {showTotals && data.map((d, i) => {
            const total = keys.reduce((a, x) => a + d[x], 0)
            const values = { x: direction === 'row' ? total : i, y: direction === 'row' ? i : total }
            return <Hint value={values} align={tooltipAlign} children={<span className='bar-total'>{total}</span>} />
          })} */}
          {interactive && tooltipValues && (
            <Hint
              value={tooltipValues}
              children={
                tooltip
                  ? tooltip(tooltipValues)
                  : BarChart.defaultProps.tooltip(tooltipValues)
              }
              align={tooltipAlign}
            />
          )}
        </FlexibleXYPlot>
      </div>
      <div className='chart-legend'>
        <DiscreteColorLegend
          items={keys.map((k) => ({
            title: Format.capitalize(legends[k]),
            color: colors[k],
          }))}
          width={200}
        />
      </div>
    </div>
  )
}

BarChart.defaultProps = {
  grids: {},
  interactive: true,
  direction: 'column',
  animate: true,
  indexBy: 'key',
  keys: ['value'],
  showAverageLine: false,
  colors: {},
  legends: {},
  tooltip: (v) => {
    const diff = v.x0 !== undefined ? v.x - v.x0 : v.x
    return (
      <div className='chart-crosshair'>
        <span className='label'>{v.label}</span>
        <span className='value'>{diff}</span>
      </div>
    )
  },
}

const labelShape = PropTypes.shape({
  attr: PropTypes.oneOf(['label']),
  title: PropTypes.string,
  position: PropTypes.oneOf(['end', 'middle', 'start']),
  tickTotal: PropTypes.number,
  tickValues: PropTypes.arrayOf(PropTypes.number),
})

BarChart.propTypes = {
  direction: PropTypes.oneOf(['column', 'row']),
  stack: PropTypes.bool,
  grids: PropTypes.shape({
    x: PropTypes.bool,
    y: PropTypes.bool,
  }),
  interactive: PropTypes.bool,
  labelLeft: labelShape,
  labelRight: labelShape,
  labelTop: labelShape,
  labelBottom: labelShape,
  tooltip: PropTypes.func,
  keys: PropTypes.arrayOf(PropTypes.string),
  indexBy: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.number,
    })
  ),
  animate: PropTypes.bool,
  showAverageLine: PropTypes.bool,
  colors: PropTypes.object,
  legends: PropTypes.object,
}
