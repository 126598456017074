import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import Icon from '@mdi/react'

export const Table = ({ data, keys }) => {
  const [sortKey, setSortKey] = useState(keys[0])
  const [sortDirection, setSortDirection] = useState(1)

  if (!data.length || !keys.length) return null

  const setSort = (key) => {
    if (key === sortKey) {
      setSortDirection(-1 * sortDirection)
    } else {
      setSortKey(key)
    }
  }

  const getType = (x) => {
    if (x === '-' || /\d+/.test(x)) return 'number'
    return typeof x
  }

  const sortedData = data.sort((x, y) => {
    let left = x[sortKey]
    let right = y[sortKey]
    if (sortDirection > 0) [left, right] = [right, left]
    if (typeof left === 'string') return left.localeCompare(right)
    else return left - right
  })

  return (
    <div className='sortable-table'>
      <table cellSpacing={0}>
        <thead>
          <tr>
            {keys.map((x, i) => {
              const type = getType(data[0][x])
              const sorting = sortKey === x
              return (
                <th
                  key={i}
                  className={type + (sorting ? ' sorting' : '')}
                  onClick={(e) => setSort(x)}
                >
                  <div className='flex-container'>
                    <span className='key flex'>{x}</span>
                    <Icon
                      path={sortDirection > 0 ? mdiChevronDown : mdiChevronUp}
                    />
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((x, i) => {
            return (
              <tr key={i}>
                {keys.map((k, j) => {
                  const type = getType(x[k])
                  return (
                    <td key={j} className={type}>
                      {x[k]}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
}
