export default {
  uniq: (arr, keyfn, isSorted = false) => {
    const seen = {}

    if (isSorted) {
      for (const item of arr) {
        const key = keyfn ? keyfn(item) : item.toString()
        if (!{}.hasOwnProperty.apply(seen, [key])) seen[key] = item
      }

      return Object.values(seen)
    } else {
      const res = []
      for (const item of arr) {
        const key = keyfn ? keyfn(item) : item.toString()
        if (!{}.hasOwnProperty.apply(seen, [key])) {
          seen[key] = true
          res.push(item)
        }
      }
      return res
    }
  },
}
