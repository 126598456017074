import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Select } from '.'

const Searchable = ({ items, namefn, onSelect, ...props }) => {
  const [text, setText] = useState('')
  const matching = items.filter(
    (x) =>
      (namefn ? namefn(x) : x)
        .toLowerCase()
        .indexOf(text.trim().toLowerCase()) > -1
  )

  const setSelected = (e, x) => {
    if (onSelect) onSelect(e, x)
    setText('')
  }

  return (
    <Select
      {...props}
      items={items}
      mutableItems={matching}
      namefn={namefn}
      onSelect={setSelected}
    >
      <Select.Header>
        <input
          value={text}
          placeholder='Search'
          onChange={(e) => setText(e.target.value)}
        />
      </Select.Header>
    </Select>
  )
}

Searchable.propTypes = {
  items: PropTypes.array,
  namefn: PropTypes.func,
  onSelect: PropTypes.func,
}

Searchable.defaultProps = {
  items: [],
}

export default Searchable
