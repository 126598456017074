import actions from '../actions'
import { ArrayFns } from '../util'

const defaultState = []

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.set_shared:
      return ArrayFns.uniq([...data, ...state], (x) => x.id)
    default:
      return state
  }
}

export default reduce
