import React from 'react'
import './index.scss'
import { connect } from 'react-redux'
import Icon from '@mdi/react'
import { mdiCheck } from '@mdi/js'

export const THEMES = [
  { name: 'Light', id: 'default', base: 'light' },
  { name: 'Dark', id: 'dark', base: 'dark' },
  { name: 'Blue', id: 'blue', base: 'light' },
  { name: 'Green', id: 'green', base: 'light' },
  { name: 'Neptune', id: 'neptune', base: 'dark' },
  { name: 'High contrast', id: 'highcontrast', base: 'dark' },
]

const ThemePicker = ({ onSelect, me }) => {
  return (
    <div className='theme-picker'>
      {THEMES.map((props) => (
        <ThemePreview
          key={props.id}
          {...props}
          onClick={() => onSelect(props.id)}
          selected={me?.color_theme === props.id}
        />
      ))}
    </div>
  )
}

const ThemePreview = ({ name, id, base, selected, onClick }) => {
  return (
    <div
      className={'theme-preview flex-container' + (selected ? ' active' : '')}
      data-theme={id}
      data-theme-base={base}
      onClick={onClick}
    >
      <div className='name flex-container flex-center'>{name}</div>
      {selected && <Icon path={mdiCheck} />}
      <div className='sidebar' />
      <div className='flex flex-container flex-vertical'>
        <div className='header' />
        <div className='main flex flex-container flex-center'>
          <div className='panel'>
            <div className='text h1' />
            <div className='text p' />
            <div className='button' />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ users }) => {
  return {
    me: Object.values(users).find((x) => x.is_you),
  }
}

export default connect(mapStateToProps)(ThemePicker)
