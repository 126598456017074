// import demoDealFieldsData from '../util/demo-dealfields'
// import demoPersonsData from '../util/demo-dealpersons'
import { workstate } from './index'
import { Api } from 'src/util'
import JSZip from 'jszip'
import saveAs from 'file-saver'

const _actions = ['set_shared']
const actions = {}

export const fetchSharedFiles = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.anon.get(`/files/share/${id}`)

    dispatch({
      type: actions.set_shared,
      data: json.data,
    })
  })
}

export const downloadAllFiles = (files) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const items = []

    for (const file of files) {
      const res = await fetch(file.downloadUrl)
      const blob = await res.blob()
      items.push({
        name: file.name,
        blob,
      })
    }

    const zip = new JSZip()
    for (const item of items) {
      zip.file(item.name, item.blob, { binary: true })
    }

    const zipFile = await zip.generateAsync({ type: 'blob' })
    saveAs(zipFile, 'shared-files-commercialsite.zip')
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
