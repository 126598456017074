import actions from 'src/actions'

const defaultState = { id: null }

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.set_active_draft:
      if (data.id === null) return defaultState
      return {
        ...state,
        ...data,
      }
    default:
      return state
  }
}

export default reduce
