import { workstate, setRelatedItems } from '.'
import { Api } from 'src/util'

const _actions = ['set_users']
const actions = {}

export const fetchUsers = (search) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get(
      `/users${search ? `?search=${search}` : ''}`,
      null,
      {},
    ).paginate(`users-${search}`, dispatch, getState)

    dispatch({
      type: actions.set_users,
      data: json.data,
    })

    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const fetchSelfUser = () => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get('/me', null, {})

    dispatch({
      type: actions.set_users,
      data: [json.data],
    })
  })
}

export const fetchUser = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get(`/users/${id}`, null, {})

    dispatch({
      type: actions.set_users,
      data: [json.data],
    })

    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const updateSelfUser = (data) => (dispatch, getState) => {
  return workstate(dispatch, 'update-self-user', async () => {
    const d = { ...data }
    delete d.id
    delete d.created_at
    delete d.updated_at
    delete d.is_you
    delete d.permissions
    delete d.team
    const json = await Api.patch('/me', d, {})

    dispatch({
      type: actions.set_users,
      data: [json.data],
    })
  })
}

export const updateUser = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    if (data.is_you) return dispatch(updateSelfUser(data))
    const d = { ...data }
    delete d.id
    delete d.created_at
    delete d.updated_at
    delete d.is_you
    delete d.permissions
    delete d.team
    const json = await Api.patch(`/users/${id}`, d, {})

    dispatch({
      type: actions.set_users,
      data: [json.data],
    })
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
