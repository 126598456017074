import actions from '../actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.set_deal_total_counts: {
      return data
    }
    default:
      return state
  }
}

export default reduce
