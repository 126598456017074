import { Api } from 'src/util'
import { workstate } from '.'

const _actions = ['append_search', 'replace_search']
const actions = {}

export const fetchSearchResults = (str, resourceFilter) => (
  dispatch,
  getState,
) => {
  return workstate(dispatch, 'search', async () => {
    const json = await Api.get(
      `/search?search=${str}${
        resourceFilter ? `&resource=${resourceFilter}` : ''
      }`,
    )

    dispatch({
      type: actions.replace_search,
      data: json.data,
    })
  })
}

export const fetchMoreSearchResults = (str, resourceFilter) => (
  dispatch,
  getState,
) => {
  const results = getState().search
  return workstate(dispatch, 'search', async () => {
    const skipObj = { deal: 0, person: 0, organization: 0, activity: 0 }
    results.forEach((x) => {
      skipObj[x.resource]++
    })

    const json = await Api.get(
      `/search?search=${str}&offset=${JSON.stringify(skipObj)}${
        resourceFilter ? `&resource=${resourceFilter}` : ''
      }`,
    )

    dispatch({
      type: actions.append_search,
      data: json.data,
    })
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
