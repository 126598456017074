import React, { createContext, useState, useRef, useContext } from 'react'
import { Modal } from 'src/components'

const ConfirmationContext = createContext(Promise.reject)

export const ConfirmationProvider = ({ children }) => {
  const [state, setState] = useState(null)
  const awaitingRef = useRef()

  const open = (options) => {
    setState(options)
    return new Promise((resolve, reject) => {
      awaitingRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    if (state.catchOnCancel && awaitingRef.current) {
      awaitingRef.current.resolve(false)
    }
    setState(null)
  }

  const handleSubmit = (res) => {
    if (awaitingRef.current) awaitingRef.current.resolve(res || true)
    setState(null)
  }

  return (
    <>
      <ConfirmationContext.Provider value={open} children={children} />
      <Modal
        active={Boolean(state)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        className='small'
        {...state}
        alert
      />
    </>
  )
}

const useConfirmation = () => useContext(ConfirmationContext)
export default useConfirmation
