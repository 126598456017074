/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Color } from 'src/util'
import './index.scss'

export const DivChart = ({ items, colors: _colors }) => {
  const keys = Object.keys(items)
  const total = Object.values(items).reduce((a, x) => a + x, 0)
  const [hovering, setHovering] = useState(null)
  const [colors, setColors] = useState(_colors)

  useEffect(() => {
    const cols = colors
    for (let i = 1; i < keys.length; ++i) {
      cols.push(Color.adjust(cols[cols.length - 1], 30))
    }
    setColors(cols)
  }, [items])

  return (
    <div className='chart chart-div'>
      <div className='bar'>
        {keys.map((str, i) => (
          <div
            key={i}
            title={str}
            className={hovering === str ? 'active' : ''}
            style={{ background: colors[i] }}
            onMouseEnter={(e) => setHovering(str)}
            onMouseLeave={(e) => setHovering(null)}
          />
        ))}
      </div>
      <div className='legend'>
        {keys.map((str, i) => {
          const num = items[str]
          return (
            <div
              key={i}
              className={'row' + (hovering === str ? ' active' : '')}
              onMouseEnter={(e) => setHovering(str)}
              onMouseLeave={(e) => setHovering(null)}
            >
              <span className='col'>{str}</span>
              <span className='col center'>{num}</span>
              <span className='col right'>
                {`${Math.floor((num / total) * 100)}%`}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
