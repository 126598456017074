import React, { useState, useRef } from 'react'
import {
  FlexibleXYPlot,
  YAxis,
  XAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineMarkSeries,
  Crosshair,
  DiscreteColorLegend,
  VerticalBarSeries,
} from 'react-vis'
import PropTypes from 'prop-types'
import { Format } from 'src/util'
import 'react-vis/dist/style.css'

export const LineChart = ({
  data,
  keys,
  colors,
  indexBy,
  labelTop,
  labelBottom,
  labelLeft,
  labelRight,
  grids,
  crosshair,
  stack,
  animate,
  interactive,
  showAverages,
  showTotals,
}) => {
  const [crosshairValues, setCrosshairValues] = useState(null)
  const lastIndex = useRef(null)

  const keysOfData = data.reduce((set, x) => {
    Object.keys(x).forEach((k) => set.add(k))
    return set
  }, new Set())
  data = data.map((x) => {
    for (const k of keysOfData) {
      if (!{}.hasOwnProperty.apply(x, [k])) x[k] = 0
    }
    return x
  })

  const setCrosshair = (value, { index }) => {
    if (lastIndex.current === index) return
    lastIndex.current = index
    const items = { ...data[index] }
    const label = items[indexBy]
    delete items[indexBy]
    setCrosshairValues(
      Object.keys(items).map((x, i) => {
        return {
          label,
          name: keys[i],
          x: index,
          y: items[x],
        }
      })
    )
  }

  const clusters = []
  const averages = []
  const total = {}
  data.forEach((x) => {
    Object.keys(x).forEach((k) => {
      if (k !== indexBy) {
        if (!total[x[indexBy]]) total[x[indexBy]] = 0
        total[x[indexBy]] += x[k]
      }
    })
  })
  for (const index in keys) {
    const key = keys[index]
    const total = 0
    const sectionData = data.map((x, i) => {
      // const color = colors[key]
      const value = x[key]
      const label = x[indexBy]
      const res = { y: value, x: i, total: total[label], label, name: key }
      return res
    })
    clusters.push({
      data: sectionData,
      cluster: 'cluster',
    })
  }
  for (let i = 0; i < data.length; ++i) {
    const d = data[i]
    let total = 0
    for (const k of keys) {
      total += d[k]
    }
    averages.push({ y: total / keys.length, x: i })
  }

  const getDomainMax = (clusters, key) => {
    if (!clusters.length) return 0
    return Math.max(...clusters.map((x) => x.data.map((y) => y[key])).flat())
  }
  const xDomainMax = Math.max(data.length - 1, 1)
  const yDomainMax = Math.max(getDomainMax(clusters, 'y'), 1)
  const maxTicks = (ticks) => Math.min(ticks, 10)

  // console.log(clusters)
  // console.log(averages)

  return (
    <div className='chart chart-bar flex flex-container'>
      <div className='chart-container flex'>
        <FlexibleXYPlot
          stackBy={stack ? 'y' : undefined}
          animation={animate ? 'stiff' : null}
          dontCheckIfEmpty={true}
          onMouseLeave={(e) => setCrosshairValues([])}
          xDomain={[0, xDomainMax]}
          yDomain={[0, yDomainMax]}
          margin={{ left: 140, right: 40, top: 40, bottom: 40 }}
          // colorType='literal'
        >
          {Object.keys(grids).length === 0 && (
            <VerticalGridLines tickTotal={xDomainMax} />
          )}
          {Object.keys(grids).length > 0 && (
            <>
              {grids.x && (
                <HorizontalGridLines tickTotal={maxTicks(yDomainMax)} />
              )}
              {grids.y && (
                <VerticalGridLines tickTotal={maxTicks(xDomainMax)} />
              )}
            </>
          )}

          {labelLeft && (
            <YAxis
              attrAxis='x'
              orientation='left'
              position='end'
              tickTotal={maxTicks(yDomainMax)}
              tickFormat={
                labelLeft.attr
                  ? (v) => {
                    if (data[v]) return data[v][indexBy]
                  }
                  : null
              }
              {...labelLeft}
              attr='y'
            />
          )}
          {labelRight && (
            <YAxis
              attrAxis='x'
              orientation='right'
              position='end'
              tickTotal={maxTicks(yDomainMax)}
              tickFormat={
                labelRight.attr
                  ? (v) => {
                    if (data[v]) return data[v][indexBy]
                  }
                  : null
              }
              {...labelRight}
              attr='y'
            />
          )}
          {labelTop && (
            <XAxis
              attrAxis='y'
              orientation='top'
              position='end'
              tickTotal={maxTicks(xDomainMax)}
              tickFormat={
                labelTop.attr
                  ? (v) => {
                    if (data[v]) return data[v][indexBy]
                  }
                  : null
              }
              {...labelTop}
              attr='x'
            />
          )}
          {labelBottom && (
            <XAxis
              attrAxis='y'
              orientation='bottom'
              position='end'
              tickTotal={maxTicks(xDomainMax)}
              tickFormat={
                labelBottom.attr
                  ? (v) => {
                    if (data[v]) return data[v][indexBy]
                  }
                  : null
              }
              {...labelBottom}
              attr='x'
            />
          )}

          {showAverages && (
            <VerticalBarSeries data={averages} barWidth={0.3} color='#dddddd' />
          )}

          {clusters.map((clusterProps, i) => {
            const extraProps =
              i === 0
                ? {
                  onNearestX: interactive ? setCrosshair : undefined,
                }
                : {}
            return (
              <LineMarkSeries
                key={i}
                {...clusterProps}
                {...extraProps}
                barWidth={0.5}
              />
            )
          })}

          {/* {showTotals && data.map((d, i) => {
            const total = keys.reduce((a, x) => a + d[x], 0)
            const values = { x: direction === 'row' ? total : i, y: direction === 'row' ? i : total }
            return <Hint value={values} align={tooltipAlign} children={<span className='bar-total'>{total}</span>} />
          })} */}
          {interactive && crosshairValues && (
            <Crosshair
              values={crosshairValues}
              children={crosshair ? crosshair(crosshairValues) : undefined}
              align={{ horizontal: 'auto', vertical: 'top' }}
            />
          )}
        </FlexibleXYPlot>
      </div>
      <div className='chart-legend'>
        <DiscreteColorLegend
          items={(showAverages
            ? [{ title: 'Average', color: '#dddddd' }]
            : []
          ).concat(keys.map((x) => Format.capitalize(x)))}
          width={200}
        />
      </div>
    </div>
  )
}

LineChart.defaultProps = {
  grids: {},
  interactive: true,
  animate: true,
  indexBy: 'key',
  keys: ['value'],
  showAverageLine: false,
}

const labelShape = PropTypes.shape({
  attr: PropTypes.oneOf(['label']),
  title: PropTypes.string,
  position: PropTypes.oneOf(['end', 'middle', 'start']),
  tickTotal: PropTypes.number,
  tickValues: PropTypes.arrayOf(PropTypes.number),
})

LineChart.propTypes = {
  stack: PropTypes.bool,
  grids: PropTypes.shape({
    x: PropTypes.bool,
    y: PropTypes.bool,
  }),
  interactive: PropTypes.bool,
  labelLeft: labelShape,
  labelRight: labelShape,
  labelTop: labelShape,
  labelBottom: labelShape,
  keys: PropTypes.arrayOf(PropTypes.string),
  indexBy: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.number,
    })
  ),
  animate: PropTypes.bool,
  showAverageLine: PropTypes.bool,
  crosshair: PropTypes.func,
}
