import { push } from 'connected-react-router'
import { workstate } from '.'
import { Api } from 'src/util'

const _actions = [
  'set_pipelines',
  'remove_pipeline',
  'set_stages',
  'delete_stage',
]
const actions = {}

export const fetchPipelines = () => async (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get('/pipelines', null, null).paginate(
      'pipelines',
      dispatch,
      getState,
    )

    const data = json.data
    dispatch({
      type: actions.set_pipelines,
      data,
    })

    let selected
    if (
      getState().router.location.pathname === '/' &&
      (selected = data.find((x) => x.selected))
    ) {
      dispatch(push(`/pipelines/${selected.id}`))
    }
  })
}

export const fetchPipeline = (id) => async (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get(`/pipelines/${id}`, null, null)

    const data = json.data
    dispatch({
      type: actions.set_pipelines,
      data: [data],
    })
  })
}

export const createPipeline = (pipeline, stages) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.post(
      '/pipelines',
      {
        pipeline,
        stages,
      },
      {},
    )

    dispatch({
      type: actions.set_pipelines,
      data: [json.data.pipeline],
    })
    dispatch({
      type: actions.set_stages,
      data: json.data.stages,
    })
    dispatch(push(`/pipelines/${json.data.pipeline.id}`))
  })
}

export const updatePipeline = ({ id, ...pipeline }, stages) => (
  dispatch,
  getState,
) => {
  return workstate(dispatch, async () => {
    const json = await Api.patch(
      `/pipelines/${id}`,
      {
        pipeline,
        stages: stages.map((x) => {
          delete x.created_at
          delete x.updated_at
          delete x.user_id
          delete x.order_nr
          return x
        }),
      },
      {},
    )
    dispatch({
      type: actions.set_pipelines,
      data: [json.data.pipeline],
    })
    const state = getState()
    const data = json.data.stages
    for (const stage of Object.values(state.stages).filter(
      (x) => x.pipeline_id === data[0].pipeline_id,
    )) {
      if (!data.find((x) => x.id === stage.id)) {
        dispatch({
          type: actions.delete_stage,
          data: stage.id,
        })
      }
    }
    dispatch({
      type: actions.set_stages,
      data: json.data.stages,
    })
    dispatch(push(`/pipelines/${json.data.pipeline.id}`))
  })
}

export const deletePipeline = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    await Api.delete(`/pipelines/${id}`, null, {})

    dispatch({
      type: actions.remove_pipeline,
      data: id,
    })
    dispatch(push('/'))
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
