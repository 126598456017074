import React, { useRef, useEffect } from 'react'
import { Calendar as _Calendar } from 'react-big-calendar'
// import { navigate as nav } from 'react-big-calendar/lib/utils/constants'
import { DayCalendar } from './DayCalendar'
import PropTypes from 'prop-types'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import dateFnsLocalizer from './dateFnsLocalizer'
import Event from './Event'
import {
  format,
  parse,
  startOfWeek,
  getDay,
  subHours,
  isYesterday,
} from 'date-fns'
// import Icon from '@mdi/react'
// import { mdiChevronLeft, mdiChevronRight, mdiCalendarToday } from '@mdi/js'

import './index.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

const locales = {
  'en-US': require('date-fns/locale/en-US'),
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const DragDropCalendar = withDragAndDrop(_Calendar)

const Header = ({ date, localizer: { format } }) => {
  return (
    <span className='day-header'>
      <span className='day-name'>{format(date, 'ccc')}</span>
      <span className='day-num'>{format(date, 'd')}</span>
    </span>
  )
}

/* const Toolbar = ({ label, onNavigate, onView, views, localizer: { messages }, ...props }) => {
  return <div className='calendar-toolbar flex-container'>
    <span className='date flex'>{label}</span>
    <div className='btn-group btn-group-h'>
      <button className='btn btn-square btn-clear no-shadow' aria-label={messages.next} onClick={e => onNavigate(nav.PREVIOUS)}><Icon path={mdiChevronLeft} /></button>
      <button className='btn btn-square btn-clear no-shadow' aria-label={messages.today} onClick={e => onNavigate(nav.TODAY)}><Icon path={mdiCalendarToday} /></button>
      <button className='btn btn-square btn-clear no-shadow' aria-label={messages.previous} onClick={e => onNavigate(nav.NEXT)}><Icon path={mdiChevronRight} /></button>
    </div>
    {views.length > 1 &&
      <div className='btn-group btn-group-h views'>
        {views.map((v, i) => (
          <button key={i} className='btn' aria-label={v} onClick={e => onView(v)}>{messages[v]}</button>
        ))}
      </div>
    }
  </div>
} */

export const Calendar = ({
  events,
  view,
  date,
  onAddEvent,
  onMoveEvent,
  onResizeEvent,
  onNavigate,
  onSelect,
  onView,
  ...props
}) => {
  const scrollTime = useRef(null)
  useEffect(() => {
    if (events.length && events[0].allDay) {
      if (!scrollTime.current) {
        scrollTime.current = subHours(new Date(), 4)
        if (isYesterday(scrollTime.current)) scrollTime.current = new Date()
      }
    }
  }, [events])
  return (
    <div className='calendar flex'>
      <DragDropCalendar
        date={date}
        components={{
          toolbar: () => null,
          header: Header,
          event: Event,
        }}
        formats={{
          dayHeaderFormat: 'cccc, MMM dd',
          dayFormat: 'ccc d',
          timeGutterFormat: 'h a',
        }}
        localizer={localizer}
        startAccessor='start'
        endAccessor='end'
        events={[
          ...events,
          {
            start: new Date(),
            end: new Date(),
            title: '',
            hide: true,
          },
        ]}
        eventPropGetter={(e) => (e.hide ? { style: { display: 'none' } } : {})}
        view={view}
        onView={onView}
        views={['month', 'week']}
        step={15}
        timeslots={4}
        scrollToTime={scrollTime.current}
        selectable
        resizable
        onSelectSlot={onAddEvent}
        onSelectEvent={onSelect}
        onEventResize={onResizeEvent}
        onEventDrop={onMoveEvent}
        onNavigate={onNavigate}
        dayLayoutAlgorithm='no-overlap'
        {...props}
      />
    </div>
  )
}

Calendar.Header = Header
Calendar.Event = Event

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  events: PropTypes.array,
  onAddEvent: PropTypes.func.isRequired,
  onMoveEvent: PropTypes.func.isRequired,
  onResizeEvent: PropTypes.func.isRequired,
  onView: PropTypes.func,
}

Calendar.defaultProps = {
  date: new Date(),
  events: [],
  view: 'week',
  onNavigate: () => {},
  onAddEvent: () => {},
  onMoveEvent: () => {},
  onResizeEvent: () => {},
  onView: () => {},
}

Calendar.Day = DayCalendar
export { DayCalendar }
