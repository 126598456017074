import actions from '../actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  let obj = {}
  switch (type) {
    case actions.set_roles: {
      if (!data) return state
      for (const d of data) {
        obj[d.id] = {
          ...state[d.id],
          ...d,
        }
      }
      return {
        ...state,
        ...obj,
      }
    }
    case actions.delete_role:
      obj = { ...state }
      delete obj[data]
      return obj
    default:
      return state
  }
}

export default reduce
