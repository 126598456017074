import { workstate, setRelatedItems } from '.'
import { Api } from 'src/util'

const _actions = ['set_statistics']
const actions = {}

export const fetchStatistics = (resource, params) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    let json
    if (params.action === 'conversion') {
      json = await Api.post(`/statistics/${resource}/${params.action}`, params)
    } else {
      json = await Api.post(`/statistics/${resource}`, params)
    }

    dispatch({
      type: actions.set_statistics,
      data: json.data,
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
