import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import actions from 'src/actions'
import pipelines from './pipelines'
import deals from './deals'
import dealTotals from './deal-totals'
import stages from './stages'
import users from './users'
import teams from './teams'
import activities from './activities'
import flows from './flows'
import dealFields from './deal-fields'
import dealTemplates from './deal-templates'
import organizations from './organizations'
import organizationFields from './organization-fields'
import persons from './persons'
import personFields from './person-fields'
import rentRollFields from './rent-roll-fields'
import rentRolls from './rent-rolls'
import incomeExpensesFields from './income-expenses-fields'
import mailMessages from './mail-messages'
import mailAttachments from './mail-attachments'
import mailMailboxes from './mail-mailboxes'
import mailConversations from './mail-conversations'
import mailDraft from './mail-draft'
import summary from './summary'
import pfs from './pfs'
import mailLinks from './mail-links'
import dashboards from './dashboards'
import statistics from './statistics'
import sharedFiles from './shared-files'
import search from './search'
import pagination from './pagination'
import roles from './roles'
import availabilities from './availabilities'

export const defaultState = {
  working: [],
  error: '',
  warning: '',
  success: '',
  mailbox_disabled: false,
  system_info: {},
  waiting_for_server_cold_start:
    process.env.NODE_ENV === 'test' || window.Cypress
      ? false
      : !window.sessionStorage.getItem('ca_cold_started'),
}
const appState = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.set_working:
      if (data.action === 'start') {
        return {
          ...state,
          working: state.working.concat({
            uuid: data.uuid,
            name: data.name,
          }),
        }
      } else {
        return {
          ...state,
          working: state.working.filter((x) => x.uuid !== data.uuid),
        }
      }
    case actions.set_error:
      if (state.error === data) return state
      return {
        ...state,
        error: data,
      }
    case actions.set_warning:
      if (state.warning === data) return state
      return {
        ...state,
        warning: data,
      }
    case actions.set_success:
      if (state.success === data) return state
      return {
        ...state,
        success: data,
      }
    case actions.set_mailbox_disabled:
      return {
        ...state,
        mailbox_disabled: data,
      }
    case actions.set_system_info:
      return {
        ...state,
        system_info: data,
      }
    case actions.set_waiting_for_server_cold_start:
      window.sessionStorage.setItem('ca_cold_started', !data)
      return {
        ...state,
        waiting_for_server_cold_start: data,
      }
    default:
      return state
  }
}

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appState,
    activities,
    pipelines,
    deals,
    dealTotals,
    stages,
    users,
    teams,
    flows,
    dealFields,
    dealTemplates,
    organizations,
    organizationFields,
    persons,
    personFields,
    rentRollFields,
    rentRolls,
    incomeExpensesFields,
    mailMessages,
    mailAttachments,
    mailMailboxes,
    mailConversations,
    mailDraft,
    summary,
    pfs,
    mailLinks,
    dashboards,
    statistics,
    sharedFiles,
    search,
    pagination,
    roles,
    availabilities,
  })
