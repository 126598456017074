/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import {
  mdiFormatBold,
  mdiFormatListNumbered,
  mdiFormatListBulleted,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiFormatQuoteOpen,
  mdiCodeTags,
  mdiFormatHeader1,
  mdiFormatHeader2,
} from '@mdi/js'
import { insertField } from './Field'
import './index.scss'
import {
  MarkButton,
  BlockButton,
  LinkButton,
  insertLink,
  toggleMark,
  wrapLink,
} from './FormatButtons'
import { Slate, withReact, Editable } from 'slate-react'
import { withHistory } from 'slate-history'
import { createEditor } from 'slate'
import isHotkey from 'is-hotkey'
import Element from './Element'
import Leaf from './Leaf'
import { serializeHtml, serializePlain } from './Serialize'
import { deserializeHtml } from './Deserialize/html'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const URLREG =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/

export const TextEditor = ({
  children,
  value: _value,
  onSave: save,
  html,
  onChange,
  className,
  placeholder,
}) => {
  const editor = useMemo(
    () =>
      withInlineTextBlocks(
        withFields(withLinks(withHistory(withReact(createEditor()))))
      ),
    []
  )
  // let defaultValue = html ? deserializeHtml(html) : _value
  // if (defaultValue.length === 1 && defaultValue[0].type === 'paragraph' && defaultValue[0].children.length === 0) defaultValue = _value
  const [value, setValue] = useState(_value)

  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])

  const [linkInput, setLinkInput] = useState(null)
  const linkInputRef = useRef(null)

  useEffect(() => {
    if (onChange) doChange()
  }, [value])
  useEffect(() => {
    if (
      html &&
      html.length > 7 &&
      value.length === 1 &&
      value[0].type === 'paragraph' &&
      value[0].children.length === 1 &&
      value[0].children[0].text === ''
    ) {
      const newValue = deserializeHtml(html)
      if (
        !(
          newValue.length === 1 &&
          newValue[0].type === 'paragraph' &&
          newValue[0].children.length === 0
        )
      ) {
        // newValue.unshift({
        //   type: 'paragraph',
        //   children: [{
        //     text: ''
        //   }]
        // })
        // Transforms.insertNodes(editor, newValue)
        setValue(newValue)
        // Transforms.select(editor, [0, 0]) // magic to select the beginning of the editor
      }
    }
  }, [html])

  const promptLink = (v) => {
    setLinkInput(v)
    setTimeout(() => {
      linkInputRef.current.focus()
    }, 0)
  }

  const confirmLink = (e) => {
    e.preventDefault()
    insertLink(editor, linkInput.value, linkInput.text)
    setLinkInput(null)
  }

  const onLinkInputKeyDown = (e) => {
    if (e.which === 13) confirmLink(e)
  }

  const onSave = (e) => {
    e.preventDefault()
    const res = {
      raw: serializePlain(editor.children),
      html: serializeHtml(editor),
    }
    if (save) save(res)
    clearEditor()
    return res
  }

  const doChange = () => {
    onChange({
      raw: serializePlain(editor.children),
      html: serializeHtml(editor),
    })
  }

  const clearEditor = () => {
    editor.selection = null
    editor.operations = []
    editor.marks = null
    setValue(_value)
  }

  const doInsertField = ({ type, value }) => {
    insertField(editor, type, value)
  }

  const getDefaultButtons = () => {
    return (
      <>
        <button className='btn btn-clear' onClick={clearEditor}>
          Clear
        </button>
        <button className='btn primary' onClick={onSave}>
          Save
        </button>
      </>
    )
  }

  const getControls = () => {
    return (
      <div className='controls flex'>
        <MarkButton format='bold' path={mdiFormatBold} />
        <MarkButton format='italic' path={mdiFormatItalic} />
        <MarkButton format='underline' path={mdiFormatUnderline} />
        <MarkButton format='code' path={mdiCodeTags} />
        <LinkButton openModal={(v) => promptLink(v)} />
        <BlockButton format='heading-one' path={mdiFormatHeader1} />
        <BlockButton format='heading-two' path={mdiFormatHeader2} />
        <BlockButton format='block-quote' path={mdiFormatQuoteOpen} />
        <BlockButton format='numbered-list' path={mdiFormatListNumbered} />
        <BlockButton format='bulleted-list' path={mdiFormatListBulleted} />
      </div>
    )
  }

  const getEditor = () => {
    return (
      <div className={'editor ' + className}>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder || 'Start typing...'}
          spellCheck
          autoFocus
          onKeyDown={(e) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, e)) {
                e.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
      </div>
    )
  }

  const getLinkInput = () => {
    if (!linkInput) return null
    return (
      <div className='link-input'>
        {linkInput.text !== undefined && (
          <label>
            <span>Text:</span>
            <input
              placeholder='Link text'
              value={linkInput.text || ''}
              ref={linkInputRef}
              onKeyDown={onLinkInputKeyDown}
              onChange={(e) =>
                setLinkInput({ ...linkInput, text: e.target.value })
              }
            />
          </label>
        )}
        <label>
          <span>Link:</span>
          <input
            type='url'
            placeholder='https://'
            value={linkInput.value || ''}
            ref={linkInput.text === undefined ? linkInputRef : null}
            onKeyDown={onLinkInputKeyDown}
            onChange={(e) =>
              setLinkInput({ ...linkInput, value: e.target.value })
            }
          />
        </label>

        <div className='buttons'>
          <button className='btn btn-clear' onClick={(e) => setLinkInput(null)}>
            Cancel
          </button>
          <button className='btn primary' onClick={confirmLink}>
            Add link
          </button>
        </div>
      </div>
    )
  }

  return (
    <Slate editor={editor} value={value} onChange={(value) => setValue(value)}>
      {children({
        getEditor,
        getDefaultButtons,
        getControls,
        getLinkInput,
        clearEditor,
        onSave,
        insertField: doInsertField,
      })}
    </Slate>
  )
}

const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor
  editor.isInline = (el) => {
    return el.type === 'link' ? true : isInline(el)
  }
  editor.insertText = (text) => {
    if (text && URLREG.test(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }
  editor.insertData = (data) => {
    const text = data.getData('text/plain')
    if (text && URLREG.test(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }
  return editor
}

const withInlineTextBlocks = (editor) => {
  const { isInline } = editor

  editor.isInline = (el) => {
    return ['italic', 'bold', 'underline', 'code', 'strikethrough'].indexOf(
      el.type
    ) > -1
      ? true
      : isInline(el)
  }
  return editor
}

const withFields = (editor) => {
  const { isInline, isVoid } = editor

  editor.isInline = (el) => {
    return el.type === 'field' ? true : isInline(el)
  }
  editor.isVoid = (el) => {
    return el.type === 'field' ? true : isVoid(el)
  }
  return editor
}

TextEditor.defaultProps = {
  value: [
    {
      type: 'paragraph',
      children: [
        {
          text: '',
        },
      ],
    },
  ],
  className: '',
}
