import { useLayoutEffect } from 'react'

const body = document.body

export const useLockScroll = (_el) => {
  useLayoutEffect(() => {
    const el = _el ?? body
    const originalStyle = window.getComputedStyle(el).overflow
    el.style.overflow = 'hidden'
    return () => (el.style.overflow = originalStyle)
  }, [_el])
}
