import actions from 'src/actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  let d = {}
  switch (type) {
    case actions.set_linked_messages:
      if (!data) return state
      for (const m of data) {
        d[m.message_id] = {
          ...state[m.message_id],
          ...m,
        }
      }
      return {
        ...state,
        ...d,
      }
    case actions.delete_linked_message:
      d = { ...state }
      delete d[data]
      return d
    default:
      return state
  }
}

export default reduce
