import { workstate, setRelatedItems } from './index'
import { Api } from 'src/util'

const _actions = ['set_linked_messages']
const actions = {}

export const fetchLinkedMessages = (conversationIds) => (
  dispatch,
  getState,
) => {
  return workstate(dispatch, async () => {
    const json = await Api.get(`/mail?ids=${conversationIds.join(',')}`)
    dispatch({
      type: actions.set_linked_messages,
      data: json.data,
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const updateLinkedMessage = (conversationId, data) => (
  dispatch,
  getState,
) => {
  return workstate(dispatch, async () => {
    const json = await Api.patch(`/mail/conversation/${conversationId}`, data)
    dispatch({
      type: actions.set_linked_messages,
      data: json.data instanceof Array ? json.data : [json.data],
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
