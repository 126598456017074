import { Api } from 'src/util'
import { workstate } from '.'

const _actions = ['set_stages']
const actions = {}

export const fetchStages = (pipelineID) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get(
      `/pipelines/${pipelineID}/stages`,
      null,
      {},
    ).paginate(`stages-${pipelineID}`, dispatch, getState)

    dispatch({
      type: actions.set_stages,
      data: json.data,
    })
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
