import React from 'react'
import { Button } from './Button'

export const IconButton = ({ children, ...props }) => {
  return (
    <Button {...props} shape='square'>
      {children}
    </Button>
  )
}

IconButton.propTypes = Button.propTypes
