import actions from '../actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  const obj = {}
  switch (type) {
    case actions.set_statistics:
      if (!data) return state
      for (const d of data) {
        obj[d.key] = {
          ...state[d.key],
          ...d,
        }
      }
      return {
        ...state,
        ...obj,
      }
    default:
      return state
  }
}

export default reduce
