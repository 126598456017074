import actions from 'src/actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  switch (type) {
    case actions.add_messages: {
      if (!data) return state
      const messages = {}
      for (const message of data) {
        messages[message.id] = {
          ...state[message.id], // because MS Graph sometimes doesnt return the email body even though it is requested
          ...message,
        }
      }
      return {
        ...state,
        ...messages,
      }
    }
    case actions.update_message:
      return {
        ...state,
        [data.messageID]: data.value,
      }
    case actions.delete_messages: {
      const newState = { ...state }
      for (const id of data) {
        delete newState[id]
      }
      return {
        ...newState,
      }
    }
    default:
      return state
  }
}

export default reduce
