import actions from '../actions'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  let obj = {}
  switch (type) {
    case actions.set_activities:
      if (!data) return state
      for (const a of data) {
        obj[a.id] = {
          ...state[a.id],
          ...a,
        }
      }
      return {
        ...state,
        ...obj,
      }
    case actions.delete_activity:
      obj = { ...state }
      delete obj[data]
      return { ...obj }
    default:
      return state
  }
}

export default reduce
