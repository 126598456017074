import React from 'react'
import './field.scss'
import Icon from '@mdi/react'
import { mdiAccount } from '@mdi/js'
import { Transforms } from 'slate'

export const insertField = (editor, fieldType, fieldValue) => {
  const field = {
    type: 'field',
    fieldValue,
    fieldType,
    children: [{ text: 'abcdef' }],
  }
  Transforms.insertNodes(editor, field)
  Transforms.move(editor)
}

const Field = ({ attributes, element, children, ...props }) => {
  const { fieldType } = element
  // const [value, setValue] = useState(fieldValue)
  // const [customValue, setCustomValue] = useState(override || fieldValue)
  // const editor = useSlate()
  // const placeholder = 'placeholder'

  return (
    <em className='field-editor' {...attributes}>
      <Icon path={mdiAccount} />
      {/* <input className='editor-value flex' defaultValue={value} onChange={e => setValue(e.target.value)} onClick={e => e.stopPropagation()} /> */}
      <span className='placeholder'>{fieldType}</span>
      <span className='editor-value'>{children}</span>
    </em>
  )
}

export default Field
