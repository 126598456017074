import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'
import { format, isPast } from 'date-fns'

const Event = memo(({ event }) => {
  const { title, start, end, icon, allDay } = event
  const isOverdue = event.id ? isPast(start) && !event.done : false
  return (
    <div className={'calendar-event' + (isOverdue ? ' overdue' : '')}>
      <header>
        <div className='title-container flex-container'>
          {icon && <Icon path={icon} />}
          <span className='title'>
            {title}
            {isOverdue ? ' (overdue)' : ''}
          </span>
        </div>
        <div className='time'>
          {allDay ? (
            <span>All day</span>
          ) : (
            <>
              <span className='from'>{format(start, 'h:mm a')}</span>
              <span className='dash'>-</span>
              <span className='to'>{format(end, 'h:mm a')}</span>
            </>
          )}
        </div>
      </header>
      <main>
        {event.notes && (
          <div dangerouslySetInnerHTML={{ __html: event.notes }} />
        )}
      </main>
    </div>
  )
})

Event.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
    icon: PropTypes.string,
  }).isRequired,
}

export default Event
