/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useMemo } from 'react'
import Icon from '@mdi/react'
import { mdiChevronDown } from '@mdi/js'
import PropTypes from 'prop-types'
import './index.scss'
import { Dropdown } from './Dropdown'
import Searchable from './Searchable'

const stopProp = (e) => {
  e.persist?.()
  e.stopPropagation()
}

function Header (props) {
  return (
    <div className='dropdown-header' onClick={stopProp}>
      {props.children}
    </div>
  )
}
Header.isHeader = true

function Footer (props) {
  return (
    <div className='dropdown-footer' onClick={stopProp}>
      {props.children}
    </div>
  )
}
Footer.isFooter = true

export const Select = React.forwardRef(
  (
    {
      className,
      children,
      items,
      mutableItems,
      value,
      active,
      itemRenderer,
      indexfn,
      namefn,
      icon,
      square,
      onChange,
      onSelect,
      onOpen,
      onClose,
      defaultText,
      header: _header,
      footer: _footer,
      readOnly,
      disabled,
      ...props
    },
    ref
  ) => {
    const [open, setOpen] = useState(null)
    const a = active === undefined ? value : active
    const index = useMemo(() => {
      try {
        return indexfn && items?.length ? indexfn(items, a) : a
      } catch (e) {
        return a
      }
    }, [a, indexfn, items])
    const activeItem = items[index]
    const header =
      _header ||
      (children &&
        (children.length
          ? children.find((x) => x.type.isHeader)
          : children.type.isHeader && children))
    const footer =
      _footer ||
      (children &&
        (children.length
          ? children.find((x) => x.type.isFooter)
          : children.type.isFooter && children))

    const containerRef = useRef(ref)
    const styleRef = useRef(null)

    useEffect(() => {
      if (open) onOpen()
      else if (open === false) onClose()
    }, [open])

    if (!items.length) disabled = true

    return (
      <div
        className={
          'select-container' +
          (open ? ' open' : '') +
          (square ? ' square' : '') +
          (readOnly || disabled ? ' disabled' : '')
        }
        onClick={() => (!(readOnly || disabled) ? setOpen(!open) : {})}
        ref={(el) => {
          containerRef.current = el
          if (ref) ref.current = el
        }}
        {...props}
      >
        {icon && <Icon path={icon} />}
        <span className='active-text'>
          {!activeItem ? defaultText : namefn ? namefn(activeItem) : activeItem}
        </span>
        {!square && <Icon path={mdiChevronDown} />}

        {open && (
          <Dropdown
            className={className}
            open={open}
            setOpen={setOpen}
            container={containerRef}
            styleRef={styleRef}
            header={header}
            footer={footer}
            items={mutableItems || items}
            itemRenderer={itemRenderer}
            indexfn={indexfn}
            index={index}
            namefn={namefn}
            onSelect={onSelect || onChange}
          />
        )}
        {children &&
          children.length &&
          children.filter((x) => !x.type?.isHeader && !x.type?.isFooter)}
      </div>
    )
  }
)

Select.Header = Header
Select.Footer = Footer
Select.Searchable = Searchable

Select.propTypes = {
  items: PropTypes.array.isRequired,
  active: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

Select.defaultProps = {
  items: [],
  onOpen: () => null,
  onClose: () => null,
}

export { Dropdown }
