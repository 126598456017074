import React from 'react'
import { makeVisFlexible, YAxis, XAxis, Sankey } from 'react-vis'
import PropTypes from 'prop-types'
import 'react-vis/dist/style.css'

const FlexibleSankey = makeVisFlexible(Sankey)

export const SankeyChart = ({
  nodes,
  links,
  keys,
  colors,
  indexBy,
  labelTop,
  labelBottom,
  labelLeft,
  labelRight,
  grids,
  crosshair,
  stack,
  animate,
  interactive,
  showAverageLine,
  showTotals,
}) => {
  const xDomainMax = 1
  const yDomainMax = 1
  const maxTicks = (ticks) => Math.min(ticks, 10)
  // console.log(nodes, links)

  // console.log(clusters)

  return (
    <div className='chart chart-sankey flex flex-container'>
      <div className='chart-container flex'>
        <FlexibleSankey
          nodes={nodes}
          links={links}
          nodePadding={20}
          hasVoronoi={true}
          align='center'
        >
          {labelLeft && (
            <YAxis
              attrAxis='x'
              orientation='left'
              position='end'
              tickTotal={maxTicks(yDomainMax)}
              tickFormat={labelLeft.attr ? (v) => {} : null}
              {...labelLeft}
              attr='y'
            />
          )}
          {labelRight && (
            <YAxis
              attrAxis='x'
              orientation='right'
              position='end'
              tickTotal={maxTicks(yDomainMax)}
              tickFormat={labelRight.attr ? (v) => {} : null}
              {...labelRight}
              attr='y'
            />
          )}
          {labelTop && (
            <XAxis
              attrAxis='y'
              orientation='top'
              position='end'
              tickTotal={maxTicks(xDomainMax)}
              tickFormat={labelTop.attr ? (v) => {} : null}
              {...labelTop}
              attr='x'
            />
          )}
          {labelBottom && (
            <XAxis
              attrAxis='y'
              orientation='bottom'
              position='end'
              tickTotal={maxTicks(xDomainMax)}
              tickFormat={labelBottom.attr ? (v) => {} : null}
              {...labelBottom}
              attr='x'
            />
          )}
        </FlexibleSankey>
      </div>
      {/* <div className='chart-legend'>
        <DiscreteColorLegend items={keys} width={200} />
      </div> */}
    </div>
  )
}

SankeyChart.defaultProps = {
  grids: {},
  interactive: true,
  animate: true,
  indexBy: 'key',
  keys: ['value'],
  showAverageLine: false,
}

const labelShape = PropTypes.shape({
  attr: PropTypes.oneOf(['label']),
  title: PropTypes.string,
  position: PropTypes.oneOf(['end', 'middle', 'start']),
  tickTotal: PropTypes.number,
  tickValues: PropTypes.arrayOf(PropTypes.number),
})

SankeyChart.propTypes = {
  stack: PropTypes.bool,
  grids: PropTypes.shape({
    x: PropTypes.bool,
    y: PropTypes.bool,
  }),
  interactive: PropTypes.bool,
  labelLeft: labelShape,
  labelRight: labelShape,
  labelTop: labelShape,
  labelBottom: labelShape,
  keys: PropTypes.arrayOf(PropTypes.string),
  indexBy: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.number,
    })
  ),
  animate: PropTypes.bool,
  showAverageLine: PropTypes.bool,
  crosshair: PropTypes.func,
}
