import { v4 as uuid } from 'uuid'
import { Errors, Api } from 'src/util'
import deals from './deals'
import pipelines from './pipelines'
import stages from './stages'
import users from './users'
import organizations from './organizations'
import persons from './persons'
import teams from './teams'
import mail from './mail'
import activities from './activities'
import summary from './summary'
import flows from './flows'
import bulk from './bulk'
import customFields from './custom-fields'
import mailLinks from './mail-links'
import dashboards from './dashboards'
import statistics from './statistics'
import sharedFiles from './shared-files'
import search from './search'
import pagination from './pagination'
import roles from './roles'
import availabilities from './availability'
export * from './deals'
export * from './pipelines'
export * from './stages'
export * from './users'
export * from './organizations'
export * from './persons'
export * from './teams'
export * from './mail'
export * from './activities'
export * from './summary'
export * from './flows'
export * from './bulk'
export * from './custom-fields'
export * from './mail-links'
export * from './dashboards'
export * from './statistics'
export * from './shared-files'
export * from './search'
export * from './pagination'
export * from './roles'
export * from './availability'

const { HttpError } = Errors

let successTimer

const _actions = [
  'set_working',
  'set_error',
  'set_warning',
  'set_success',
  'set_users',
  'set_organizations',
  'set_persons',
  'set_stages',
  'set_pipelines',
  'set_activities',
  'set_flows',
  'set_deals',
  'set_deal_fields',
  'set_rent_roll_fields',
  'set_income_expenses_fields',
  'set_person_fields',
  'set_organization_fields',
  'set_linked_messages',
  'set_system_info',
  'set_waiting_for_server_cold_start',
]
const actions = {
  ...deals,
  ...pipelines,
  ...stages,
  ...users,
  ...organizations,
  ...persons,
  ...teams,
  ...mail,
  ...activities,
  ...summary,
  ...flows,
  ...bulk,
  ...customFields,
  ...mailLinks,
  ...dashboards,
  ...statistics,
  ...sharedFiles,
  ...search,
  ...pagination,
  ...roles,
  ...availabilities,
}

export const setError = (str) => ({
  type: actions.set_error,
  data: str,
})

export const setWarning = (str) => ({
  type: actions.set_warning,
  data: str,
})

export const doSuccess = (str) => (dispatch) => {
  dispatch({
    type: actions.set_success,
    data: str,
  })
  clearTimeout(successTimer)
  successTimer = setTimeout(() => {
    dispatch({
      type: actions.set_success,
      data: '',
    })
  }, 3000)
}

export const fetchSystemInfo = () => async (dispatch) => {
  if (window.Cypress || process.env.NODE_ENV === 'test') return
  try {
    const res = await Api.get('/system')
    dispatch({
      type: actions.set_system_info,
      data: res.data,
    })
  } catch (e) {}
}

const setWaitingForServerColdStart = (data) => (dispatch, getState) => {
  if (process.env.NODE_ENV === 'test' || window.Cypress) return
  const {
    app: { waiting_for_server_cold_start },
    users,
  } = getState()
  if (!waiting_for_server_cold_start) return
  const me = Object.values(users).find((x) => x.is_you)
  if (!me) return
  dispatch({
    type: actions.set_waiting_for_server_cold_start,
    data,
  })
}

export const setRelatedItems = (data) => (dispatch) => {
  for (const k in data) {
    switch (k) {
      case 'deals':
        dispatch({
          type: actions.set_deals,
          data: data[k],
        })
        break
      case 'users':
        dispatch({
          type: actions.set_users,
          data: data[k],
        })
        break
      case 'organizations':
        dispatch({
          type: actions.set_organizations,
          data: data[k],
        })
        break
      case 'persons':
        dispatch({
          type: actions.set_persons,
          data: data[k],
        })
        break
      case 'stages':
        dispatch({
          type: actions.set_stages,
          data: data[k],
        })
        break
      case 'pipelines':
        dispatch({
          type: actions.set_pipelines,
          data: data[k],
        })
        break
      case 'activities':
        dispatch({
          type: actions.set_activities,
          data: data[k],
        })
        break
      case 'flows':
        dispatch({
          type: actions.set_flows,
          data: data[k],
        })
        break
      case 'roles':
        dispatch({ type: actions.set_roles, data: data[k] })
        break
      case 'teams':
        dispatch({ type: actions.set_teams, data: data[k] })
        break
      case 'dealFields':
        dispatch({ type: actions.set_deal_fields, data: data[k] })
        break
      case 'rentRollFields':
        dispatch({ type: actions.set_rent_roll_fields, data: data[k] })
        break
      case 'incomeExpensesFields':
        dispatch({ type: actions.set_income_expenses_fields, data: data[k] })
        break
      case 'personFields':
        dispatch({ type: actions.set_person_fields, data: data[k] })
        break
      case 'organizationFields':
        dispatch({ type: actions.set_organization_fields, data: data[k] })
        break
      case 'mails':
        dispatch({ type: actions.set_linked_messages, data: data[k] })
        break
      default:
        continue
    }
  }
}

export const workstate = async (dispatch, section, fn) => {
  let actionName = 'loading'
  if (fn === undefined) {
    fn = section
  } else {
    actionName = section
  }
  const uid = uuid()
  dispatch({
    type: actions.set_working,
    data: {
      action: 'start',
      uuid: uid,
      name: actionName,
    },
  })
  dispatch(setError(''))
  dispatch(setWarning(''))
  try {
    return await fn()
  } catch (e) {
    if (process.env.NODE_ENV !== 'test') console.warn(e)
    let msg = ''
    if (e.message) msg = e.message.trim()
    if (msg === 'Failed to fetch') msg = 'Could not connect to server'
    // fetch error
    else if (msg === 'access token has expired') {
      msg = 'Access token has expired. Please refresh the page.'
    }
    if (e instanceof HttpError) {
      if (
        ['0', '4', '5'].includes(e.status.toString()[0]) &&
        e.status !== 404
      ) {
        dispatch(setError(msg))
      } else {
        dispatch(setWarning(msg))
      }
      return e
    } else {
      dispatch(setWarning(msg))
      return new Error(msg)
    }
  } finally {
    dispatch({
      type: actions.set_working,
      data: {
        action: 'stop',
        uuid: uid,
      },
    })
    dispatch(setWaitingForServerColdStart(false))
  }
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
