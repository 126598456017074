import actions from 'src/actions'
const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  let obj = {}
  switch (type) {
    case actions.set_stages: {
      if (!data) return state
      const s = { ...state }
      for (const stage of data) {
        obj[stage.id] = {
          ...s[stage.id],
          ...stage,
        }
      }
      // for (const stage of Object.values(state).filter(x => x.pipeline_id === data[0].pipeline_id)) {
      //   if (!data.find(x => x.id === stage.id)) delete s[stage.id]
      // }
      return {
        ...s,
        ...obj,
      }
    }
    case actions.delete_stage:
      obj = { ...state }
      delete obj[data]
      return obj
    default:
      return state
  }
}

export default reduce
