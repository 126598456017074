import { workstate, setRelatedItems } from './index'
import { Api } from 'src/util'

const _actions = [
  'delete_deal_field',
  'delete_person_field',
  'delete_organization_field',
  'delete_income_expenses_field',
  'delete_rent_roll_field',
]
const actions = {}

export const createCustomField = (resource, data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.post(`/fields/${resource}`, data)
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const updateCustomField = (resource, id, data) => (
  dispatch,
  getState,
) => {
  return workstate(dispatch, async () => {
    const json = await Api.patch(`/fields/${resource}/${id}`, data)
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const deleteCustomField = (resource, id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    await Api.delete(`/fields/${resource}/${id}`)
    switch (resource) {
      case 'deals':
        dispatch({ type: actions.delete_deal_field, data: id })
        break
      case 'persons':
        dispatch({ type: actions.delete_person_field, data: id })
        break
      case 'organizations':
        dispatch({ type: actions.delete_organization_field, data: id })
        break
      case 'incomeExpenses':
        dispatch({ type: actions.delete_income_expenses_field, data: id })
        break
      case 'rentRolls':
        dispatch({ type: actions.delete_rent_roll_field, data: id })
        break
      default:
    }
  })
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
