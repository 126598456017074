import React from 'react'
import './index.scss'

const Spinner = ({ message, working, style }) => (
  <div
    className={'spinner-container' + (working ? ' working' : '')}
    style={style}
  >
    <svg
      className='spinner'
      width='65px'
      height='65px'
      viewBox='0 0 66 66'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        className='path'
        fill='none'
        strokeWidth='6'
        strokeLinecap='round'
        cx='33'
        cy='33'
        r='30'
      />
    </svg>
    {message && <p>{message}</p>}
  </div>
)

export const Progress = ({ working }) => (
  <div className={'progress' + (working ? ' working' : '')}>
    <div className='indeterminate' />
  </div>
)

Progress.Spinner = Spinner
