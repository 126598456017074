import actions from '../actions'
import { ArrayFns } from '../util'

const defaultState = {}

const reduce = (state = defaultState, { type, data }) => {
  let d = {}
  switch (type) {
    case actions.set_income_expenses_fields: {
      const sorted = {
        layout: data.filter((x) => x.section === 'layout'),
        income: data.filter((x) => x.section === 'income'),
        losses: data.filter((x) => x.section === 'losses'),
        expenses: data.filter((x) => x.section === 'expenses'),
      }
      d = { ...state }
      for (const k in sorted) {
        const arr = d[k] || []
        for (const i in arr) {
          const a = arr[i]
          const match = state[k].find((x) => x.id === a.id)
          arr[i] = {
            ...match,
            ...a,
          }
        }
        d[k] = ArrayFns.uniq(sorted[k].concat(arr), (x) => x.id).sort(
          (x, y) => x.order_nr - y.order_nr,
        )
      }
      return d
    }
    case actions.delete_income_expenses_field:
      d = { ...state }
      for (const k in d) {
        d[k] = d[k].filter((x) => x.id !== data)
      }
      return d
    default:
      return state
  }
}

export default reduce
