import { workstate } from '.'
import { Api } from 'src/util'

const _actions = ['set_summary']
const actions = {}

const fetchSummary = (section) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get(`/${section}/summary`, null, {})

    dispatch({
      type: actions.set_summary,
      data: {
        key: section,
        value: json.data,
      },
    })
  })
}

export const fetchContactsSummary = () => (dispatch) => {
  dispatch(fetchSummary('contacts'))
}

for (const a of _actions) {
  actions[a] = a
}
export default actions
