import React from 'react'
import { clsx } from 'src/util'
import PropTypes from 'prop-types'

export const Button = ({ children, color, variant, shape, flat, ...props }) => {
  return (
    <button
      {...props}
      className={clsx('btn', props.className, {
        big: variant === 'big',
        primary: color === 'primary',
        secondary: color === 'secondary',
        delete: variant === 'delete',
        filled: variant === 'contained',
        'btn-clear': variant === 'text',
        'btn-outlined': variant === 'outlined',
        'btn-round': shape === 'circle',
        'btn-square': shape === 'square',
        'no-shadow': flat,
      })}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['delete', 'contained', 'text', 'outlined', 'big']),
  shape: PropTypes.oneOf(['square', 'circle']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  flat: PropTypes.bool,
}
