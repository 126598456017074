export const clsx = (...params) => {
  return params
    .reduce((a, param) => {
      if (!param) return a

      if (typeof param === 'string' && param.length) {
        a.push(param.trim().replace(/\s{2,}/g, ' '))
      }

      if (typeof param === 'object') {
        for (const k in param) {
          if (param[k]) {
            a.push(k)
          }
        }
      }

      return a
    }, [])
    .join(' ')
}
