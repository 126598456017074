export default {
  restrictVertical: (draggableStyle) => {
    const { transform } = draggableStyle
    let activeTransform = {}
    if (transform) {
      activeTransform = {
        transform: `translate(0, ${transform.substring(
          transform.indexOf(',') + 1,
          transform.indexOf(')'),
        )})`,
      }
    }
    return {
      userSelect: 'none',
      ...draggableStyle,
      ...activeTransform,
    }
  },
  restrictHorizontal: (draggableStyle) => {
    const { transform } = draggableStyle
    let activeTransform = {}
    if (transform) {
      activeTransform = {
        transform: `translate(${transform.substring(
          transform.indexOf('(') + 1,
          transform.indexOf(','),
        )}, 0)`,
      }
    }
    return {
      userSelect: 'none',
      ...draggableStyle,
      ...activeTransform,
    }
  },
  reorder: (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    // ;[result[startIndex], result[endIndex]] = [result[endIndex], result[startIndex]]
    return result
  },
}
