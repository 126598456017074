import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import {
  routerMiddleware,
  ConnectedRouter as Router,
} from 'connected-react-router'
// import * as serviceWorker from './serviceWorker'
import reducers from 'src/reducers'
import 'src/util/polyfill'
import { AuthProvider, createAuthStore } from './msal'
import { Progress } from 'src/components'
import './App.scss'
import { Route, Switch } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { init as sentryInit } from '@sentry/browser'
import logoWhite from './assets/img/logo.svg'
import { ConfirmationProvider } from 'src/hooks/use-confirmation'
import ContextMenuProvider from 'src/components/ContextMenu/Provider'
import { THEMES } from './Settings/Profile/ThemePicker'
import SnackbarProvider from 'react-simple-snackbar'
import { history } from 'src/history'

const App = lazy(() => import('./App'))
const PublicApp = lazy(() => import('./PublicApp'))

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  const ignoredErrorMessages = ['Token calls are blocked in hidden iframes']
  sentryInit({
    dsn: 'https://40ea1e4482624d99903c912b0623e4ef@o404564.ingest.sentry.io/5283134',
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    beforeSend: (ev) => {
      const err = ev.originalException
      if (ignoredErrorMessages.some((x) => err?.message.includes(x))) {
        return null
      }
      return ev
    },
  })
}

const defaultTheme = localStorage.getItem('cs_color_theme') || 'default'
const rootEl = document.querySelector('body')
rootEl.setAttribute('data-theme', defaultTheme || 'default')
rootEl.setAttribute(
  'data-theme-base',
  defaultTheme ? THEMES.find((x) => x.id === defaultTheme)?.base : 'light'
)

const store = createStore(
  reducers(history),
  compose(
    applyMiddleware(thunk, routerMiddleware(history)),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
)

const authStore = createAuthStore({
  appID: process.env.REACT_APP_MSID,
  authority:
    'https://login.microsoftonline.com/' + process.env.REACT_APP_TENANTID,
  redirectURI: process.env.REACT_APP_REDIRECT_URI,
  scopes: ['profile', 'user.read', 'Mail.ReadWrite', 'Mail.Send'],
})

export const RootComponent = ({ component: Component, public: Public }) => (
  <Provider store={store}>
    <HelmetProvider>
      <ConfirmationProvider>
        <ContextMenuProvider>
          <SnackbarProvider>
            <Suspense
              fallback={(
                <>
                  <header className='initial-load-header'>
                    <img className='logo' src={logoWhite} alt='FMCapital' />
                    {/* <span className='logo'><span className='minimum'>FM</span><span>Capital</span></span> */}
                  </header>
                  <Progress.Spinner />
                </>
              )}
            >
              <Router history={history}>
                <AuthProvider store={authStore}>
                  <Switch>
                    <Route path='/c/:path?' component={Public} />
                    <Route>
                      <Component />
                    </Route>
                  </Switch>
                </AuthProvider>
              </Router>
            </Suspense>
          </SnackbarProvider>
        </ContextMenuProvider>
      </ConfirmationProvider>
    </HelmetProvider>
  </Provider>
)

const elem = document.getElementById('root')
const render = (Component, Public) => {
  ReactDOM.render(<RootComponent component={Component} public={Public} />, elem)
}

if (elem) render(App, PublicApp)

if (process.env.NODE_ENV === 'development' && module.hot && elem) {
  let nextApp, nextPublicApp
  module.hot.accept('./App', () => {
    nextApp = require('./App').default
    if (!nextPublicApp) nextPublicApp = require('./PublicApp').default
    console.clear()
    render(nextApp, nextPublicApp)
  })
  module.hot.accept('./PublicApp', () => {
    if (!nextApp) nextApp = require('./App').default
    nextPublicApp = require('./PublicApp').default
    console.clear()
    render(nextApp, nextPublicApp)
  })
  module.hot.accept('./reducers', () => {
    const reducers = require('./reducers').default
    store.replaceReducer(reducers(history))
  })
}

if (process.env.NODE_ENV === 'production') {
  console.info(
    '%cBuild# ' + process.env.REACT_APP_BUILD_NUMBER,
    'color:skyblue'
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
/* serviceWorker.unregister({
  onUpdate: (reg) => null,
  onSuccess: (reg) => null
}) */
