/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import './index.scss'

const SPACING = 14

export const Tooltip = React.forwardRef(
  ({ children, position, title, disabled, active, ...props }, ref) => {
    const [containerPosition, setContainerPosition] = useState(null)
    const containerRef = useRef(null)

    useEffect(() => {
      if (disabled) setContainerPosition(null)
    }, [disabled])

    useEffect(() => {
      if (active && containerRef.current && !disabled) {
        setContainerPosition(containerRef.current.getBoundingClientRect())
      } else if (!active) setContainerPosition(null)
    }, [active, containerRef])

    const tooltip = useMemo(
      () => (
        <TooltipComponent
          key='ctx-tooltip'
          open={Boolean(containerPosition)}
          containerPosition={containerPosition}
          position={position}
          title={title}
          {...props}
        />
      ),
      [containerPosition, props]
    )

    const els = useMemo(() => {
      const ca = React.Children.toArray(children)[0]

      const childChildren = React.Children.toArray(ca.props.children).map(
        (c, i) =>
          React.isValidElement(c)
            ? React.cloneElement(c, {
              key: i,
            })
            : c
      )

      return React.cloneElement(ca, {
        ref (el) {
          if (ref) ref.current = el
          containerRef.current = el
        },
        onMouseEnter () {
          if (!disabled) {
            setContainerPosition(containerRef.current.getBoundingClientRect())
          }
        },
        onMouseLeave () {
          if (!active) setContainerPosition(null)
        },
        children: [...childChildren, tooltip],
      })
    }, [children, tooltip])

    return els
  }
)

Tooltip.defaultProps = {
  position: 'top',
  disabled: false,
  active: false,
}

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.element,
  position: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
  disabled: PropTypes.bool,
  active: PropTypes.bool,
}

const TooltipComponent = ({
  open,
  position,
  containerPosition,
  title,
  ...props
}) => {
  const [style, setStyle] = useState(null)
  const selfRef = useCallback(
    (self) => {
      if (containerPosition && self) {
        const { innerHeight: maxHeight, innerWidth: maxWidth } = window
        const selfPosition = self.getBoundingClientRect()
        let res = {}
        if (position === 'top') {
          res = {
            top: containerPosition.top - SPACING - selfPosition.height,
            left:
              containerPosition.left +
              containerPosition.width / 2 -
              selfPosition.width / 2,
          }
        } else if (position === 'right') {
          res = {
            top:
              containerPosition.top +
              containerPosition.height / 2 -
              selfPosition.height / 2,
            left: containerPosition.right + SPACING,
          }
        } else if (position === 'bottom') {
          res = {
            top: containerPosition.bottom + SPACING,
            left:
              containerPosition.left +
              containerPosition.width / 2 -
              selfPosition.width / 2,
          }
        } else if (position === 'left') {
          res = {
            top:
              containerPosition.top +
              containerPosition.height / 2 -
              selfPosition.height / 2,
            left: containerPosition.left - SPACING - selfPosition.width,
          }
        }

        // prevent tooltip from going off the screen
        if (res.top < 0) res.top = 3
        if (res.left < 0) res.left = 3
        if (res.top + selfPosition.height > maxHeight) {
          res.top = maxHeight - selfPosition.height - 3
        }
        if (res.left + selfPosition.width > maxWidth) {
          res.left = maxWidth - selfPosition.width - 3
        }

        setStyle(res)
      }
    },
    [containerPosition, position]
  )

  if (!open) return null

  return createPortal(
    <div
      className={'tooltip-container ' + position}
      ref={selfRef}
      style={{ ...props.style, ...style }}
    >
      <div className='tooltip-content'>{title}</div>
    </div>,
    document.getElementById('root')
  )
}

TooltipComponent.defaultProps = {
  style: {},
}
