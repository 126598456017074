import { Text } from 'slate'

const matchHtmlRegExp = /["'&<>]/
function escapeHtml (string) {
  const str = '' + string
  const match = matchHtmlRegExp.exec(str)

  if (!match) {
    return str
  }

  let escape
  let html = ''
  let index = 0
  let lastIndex = 0

  for (index = match.index; index < str.length; index++) {
    switch (str.charCodeAt(index)) {
      case 34: // "
        escape = '&quot;'
        break
      case 38: // &
        escape = '&amp;'
        break
      case 39: // '
        escape = '&#39;'
        break
      case 60: // <
        escape = '&lt;'
        break
      case 62: // >
        escape = '&gt;'
        break
      default:
        continue
    }

    if (lastIndex !== index) {
      html += str.substring(lastIndex, index)
    }

    lastIndex = index + 1
    html += escape
  }

  return lastIndex !== index ? html + str.substring(lastIndex, index) : html
}

const wrapText = (node) => {
  let text = node.text
  if (/\r?\n/.test(text)) text = text.replace(/\r?\n/g, '<br />')
  if (node.italic) text = `<em>${text}</em>`
  if (node.bold) text = `<strong>${text}</strong>`
  if (node.underline) text = `<u>${text}</u>`
  if (node.code) text = `<code>${text}</code>`
  if (node.strikethrough) text = `<s>${text}</s>`
  if (text === '—————') text = '<hr />'
  return text
}

export const serializeHtml = (node) => {
  if (Text.isText(node)) {
    const text = escapeHtml(node.text)
    return wrapText({ ...node, text })
  }

  const children = node.children.map((n) => serializeHtml(n)).join('')
  switch (node.type) {
    case 'block':
      return `<div>${children}</div>`
    case 'bold':
      return `<strong>${children}</strong>`
    case 'italic':
      return `<em>${children}</em>`
    case 'underline':
      return `<u>${children}</u>`
    case 'code':
      return `<code>${children}</code>`
    case 'strikethrough':
      return `<s>${children}</s>`
    case 'block-quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'heading-one':
      return `<h1>${children}</h1>`
    case 'heading-two':
      return `<h2>${children}</h2>`
    case 'link':
      return `<a href="${escapeHtml(
        node.url
      )}" target="_blank" rel="noopener noreferrer">${children}</a>`
    default:
      return children
  }
}
