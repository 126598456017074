import { workstate } from './index'
import { getAuthenticatedClient } from '../msal/graph-client'
import { Constants, Files, Api, Timers } from 'src/util'
import { saveAs } from 'file-saver'
import * as MSGraph from '@microsoft/microsoft-graph-client'
import { push } from 'connected-react-router'
import { GraphError } from '@microsoft/microsoft-graph-client'

const ENDPOINT = process.env.REACT_APP_ONEDRIVE_ENDPOINT
const DUMB_EMAIL_SENT_DELAY = 1300 // because outlook takes a second to move a sent email to the sentitems folder
const DISABLED_MAIL = true

const maxRequestsPerBatch = 20
const _actions = [
  'set_mailbox_disabled',
  'set_mailbox',
  'set_mailboxes',
  'set_mailboxes_silent',
  'set_mailbox_messages',
  'set_conversation_messages',
  'add_messages',
  'set_attachments',
  'remove_attachment',
  'delete_messages',
  'update_message',
  'set_active_draft',
  'set_saving_draft',
  'set_linked_messages',
  'delete_linked_message',
]
const actions = {}

export const fetchMailboxes = (token) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    if (DISABLED_MAIL) return
    if (!token) throw new Error('missing token')
    if (process.env.NODE_ENV === 'test' || window.Cypress) {
      dispatch({
        type: actions.set_mailbox_disabled,
        data: 'Mailbox feature is disabled in the Test environment.',
      })
      return
    }
    const client = getAuthenticatedClient(token)
    try {
      const res = await client.api(`${ENDPOINT}/mailFolders`).get()
      dispatch({
        type: actions.set_mailboxes,
        data: res.value,
      })
    } catch (e) {
      if (e.code && e.code === 'MailboxNotEnabledForRESTAPI') {
        dispatch({
          type: actions.set_mailbox_disabled,
          data: 'Mail integration is not yet supported by your organization.',
        })
      } else {
        return e
      }
    }
  })
}

export const fetchMailboxesSilent = (token) => async (dispatch, getState) => {
  // no workstate, is silent and shouldnt show loading indicator
  if (DISABLED_MAIL) return
  try {
    if (!token) throw new Error('missing token')
    const client = getAuthenticatedClient(token)
    const res = await client.api(`${ENDPOINT}/mailFolders`).get()
    dispatch({
      type: actions.set_mailboxes_silent,
      data: res.value,
    })
  } catch (e) {
    if (e.code && e.code === 'MailboxNotEnabledForRESTAPI') {
      dispatch({
        type: actions.set_mailbox_disabled,
        data: true,
      })
    } else {
      return e
    }
  }
}

export const fetchMailbox = (token, folder) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    if (!token || !folder) throw new Error('missing token or folder')
    const client = getAuthenticatedClient(token)
    const res = await client.api(`${ENDPOINT}/mailFolders/${folder}`).get()
    // console.log(res)
    dispatch({
      type: actions.set_mailbox,
      data: res,
    })
  })
}

export const fetchMessages =
  (token, folder, url = '', addToStart = false) =>
    (dispatch, getState) => {
      return workstate(dispatch, async () => {
        if (!token || !folder) throw new Error('missing token or folder')
        const client = getAuthenticatedClient(token)

        const mail = await client
          .api(
            url ||
            `${ENDPOINT}/mailFolders/${folder}/messages?$top=${Constants.EMAIL_MESSAGES_PER_PAGE}&$select=id,isRead,subject,bodyPreview,createdDateTime,sentDateTime,lastModifiedDateTime,receivedDateTime,hasAttachments,from,replyTo,conversationId,conversationIndex,ccRecipients,bccRecipients,toRecipients,body,uniqueBody,parentFolderId`
          )
          .get()
        // console.log(mail)
        dispatch({
          type: actions.set_mailbox_messages,
          data: {
            addToStart,
            mailbox: folder,
            messages: mail.value.map((x) => x.id),
            next: mail['@odata.nextLink'],
          },
        })
        dispatch({
          type: actions.add_messages,
          data: mail.value,
        })
      })
    }

export const fetchConversationMessages =
  (token, folder, conversationID) => (dispatch, getState) => {
    return workstate(dispatch, async () => {
      if (!token || !folder || !conversationID) {
        throw new Error('missing token or folder or conversationID')
      }
      const client = getAuthenticatedClient(token)
      const mail = await client
        .api(`${ENDPOINT}/mailFolders/${folder}/messages`)
        .select([
          'id',
          'isRead',
          'subject',
          'bodyPreview',
          'createdDateTime',
          'sentDateTime',
          'lastModifiedDateTime',
          'receivedDateTime',
          'hasAttachments',
          'from',
          'replyTo',
          'conversationId',
          'conversationIndex',
          'ccRecipients',
          'bccRecipients',
          'toRecipients',
          'body',
          'uniqueBody',
          'parentFolderId',
        ])
        .filter(`conversationId eq '${conversationID}'`)
        .get()
      const sent = await client
        .api(`${ENDPOINT}/mailFolders/sentitems/messages`)
        .select([
          'id',
          'isRead',
          'subject',
          'bodyPreview',
          'createdDateTime',
          'sentDateTime',
          'lastModifiedDateTime',
          'receivedDateTime',
          'hasAttachments',
          'from',
          'replyTo',
          'conversationId',
          'conversationIndex',
          'ccRecipients',
          'bccRecipients',
          'toRecipients',
          'body',
          'uniqueBody',
          'parentFolderId',
        ])
        .filter(`conversationId eq '${conversationID}'`)
        .get()

      let nextMailLink = mail['@odata.nextLink']
      let nextSentLink = sent['@odata.nextLink']
      let values = mail.value.concat(sent.value)
      while (nextMailLink) {
        const res = await client.api(nextMailLink).get()
        nextMailLink = res['@odata.nextLink']
        values.concat(res.value)
      }
      while (nextSentLink) {
        const res = await client.api(nextSentLink).get()
        nextSentLink = res['@odata.nextLink']
        values.concat(res.value)
      }

      values = values.sort((x, y) => y.conversationIndex - x.conversationIndex)

      dispatch({
        type: actions.set_conversation_messages,
        data: {
          conversationID,
          value: values.map((x) => x.id),
        },
      })
      dispatch({
        type: actions.add_messages,
        data: values,
      })
    })
  }

export const fetchMessagesDelta =
  (token, folder) => async (dispatch, getState) => {
    if (!token || !folder) throw new Error('missing token or folder')
    const client = getAuthenticatedClient(token)
    const prevDelta = getState().mailMailboxes[folder].delta
    try {
      const delta = await client
        .api(
          prevDelta ||
            `${ENDPOINT}/mailFolders/${folder}/messages/delta?$deltaToken=latest`
        )
        .select([
          'id',
          'isRead',
          'subject',
          'bodyPreview',
          'body',
          'createdDateTime',
          'sentDateTime',
          'lastModifiedDateTime',
          'receivedDateTime',
          'hasAttachments',
          'from',
          'replyTo',
          'conversationId',
          'conversationIndex',
          'ccRecipients',
          'bccRecipients',
          'toRecipients',
          'uniqueBody',
          'parentFolderId',
        ])
        .get()

      const results = delta.value
      let nextLink = delta['@odata.nextLink']
      let deltaLink = delta['@odata.deltaLink']
      while (nextLink) {
        const res = await client.api(nextLink).get()
        results.concat(res.value)
        nextLink = res['@odata.nextLink']
        deltaLink = res['@odata.deltaLink']
      }

      dispatch({
        type: actions.set_mailbox_messages,
        data: {
          addToStart: true,
          mailbox: folder,
          messages: results.map((x) => x.id),
          delta: deltaLink,
        },
      })
      dispatch({
        type: actions.add_messages,
        data: results.filter((x) => x.createdDateTime),
      })
    } catch (e) {
      if (e instanceof GraphError && e.code === 'SyncStateNotFound') {
        console.warn('resetting message deltas: ' + folder)
        dispatch({
          type: actions.set_mailbox_messages,
          data: {
            addToStart: true,
            mailbox: folder,
            messages: [],
            delta: `${ENDPOINT}/mailFolders/${folder}/messages/delta?$deltaToken=latest`,
          },
        })
      }
    }
  }

export const fetchAttachments = (token, messageID) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    if (!token || !messageID) throw new Error('missing token or messageID')
    const client = getAuthenticatedClient(token)
    const attachments = await client
      .api(`${ENDPOINT}/messages/${messageID}/attachments`)
      .select(['name', 'size', 'isInline'])
      .get()

    const v = attachments.value
    for (const i in v) {
      const attachment = v[i]
      if (attachment.isInline) {
        const a = await client
          .api(`${ENDPOINT}/messages/${messageID}/attachments/${attachment.id}`)
          .get()
        v[i] = a
      }
    }
    dispatch({
      type: actions.set_attachments,
      data: {
        messageID,
        value: v,
      },
    })
  })
}

export const downloadAttachment =
  (token, messageID, attachmentID) => (dispatch, getState) => {
    return workstate(dispatch, async () => {
      if (!token || !messageID || !attachmentID) {
        throw new Error('missing token or messageID or attachmentID')
      }
      const client = getAuthenticatedClient(token)
      const res = await client
        .api(`${ENDPOINT}/messages/${messageID}/attachments/${attachmentID}`)
        .version('beta')
        .get()

      if (res.contentBytes) {
        saveAs(
          new Blob([Buffer.from(res.contentBytes, 'base64')], {
            type: res.contentType,
          }),
          res.name
        )
      } else {
        const a = document.createElement('a')
        a.setAttribute('target', '_blank')
        a.href = res.sourceUrl
        a.click()
      }
    })
  }

export const downloadPublicAttachment =
  (mailID, attachmentID) => (dispatch, getState) => {
    return workstate(dispatch, async () => {
      const json = await Api.get(`/mail/${mailID}/attachments/${attachmentID}`)
      const res = json.data
      saveAs(
        new Blob([Buffer.from(res.contentBytes, 'base64')], {
          type: res.contentType,
        }),
        res.name
      )
    })
  }

export const updateMessage =
  (token, messageID, data) => (dispatch, getState) => {
    return workstate(dispatch, async () => {
      if (!token || !messageID || !data) {
        throw new Error('missing token or messageID or data')
      }
      const client = getAuthenticatedClient(token)
      const res = await client
        .api(`${ENDPOINT}/messages/${messageID}`)
        .patch(data)
      dispatch({
        type: actions.update_message,
        data: {
          messageID,
          value: res,
        },
      })
      if ({}.hasOwnProperty.apply(data, ['idRead'])) {
        dispatch(fetchMailbox(token, res.parentFolderId))
      }
    })
  }

export const updateMessages =
  (token, messageIDs = [], data) =>
    (dispatch, getState) => {
      return workstate(dispatch, async () => {
        const client = getAuthenticatedClient(token)
        if (!token || !messageIDs.length || !data) {
          throw new Error('missing token or messageIDs or data')
        }
        let slice
        let group = 0
        const responses = []
        while (
          (slice = messageIDs.slice(
            group * maxRequestsPerBatch,
            group * maxRequestsPerBatch + maxRequestsPerBatch
          )).length
        ) {
          const steps = []
          for (const id of slice) {
            const req = new Request(`/me/messages/${id}`, {
              method: 'PATCH',
              headers: {
                'Content-type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            steps.push({
              id: steps.length + 1,
              request: req,
              dependsOn: steps.length ? [steps.length] : undefined,
            // dependsOn: steps.length > 4 ? [steps.length - 4] : undefined
            })
          }
          console.log(steps)
          const content = new MSGraph.BatchRequestContent(steps)
          const res = await client.api('/$batch').post(await content.getContent())
          const responseContent = new MSGraph.BatchResponseContent(res)
          const iter = responseContent.getResponsesIterator()
          let dat = iter.next()
          while (!dat.done) {
            responses.push(await dat.value[1].json())
            dat = iter.next()
          }
          group++
        }

        dispatch({
          type: actions.add_messages,
          data: responses,
        })
        if ({}.hasOwnProperty.apply(data, ['idRead'])) {
          dispatch(fetchMailbox(token, responses[0].parentFolderId))
        }
      })
    }

export const deleteMessages =
  (token, mailbox, messageIDs = []) =>
    (dispatch, getState) => {
      return workstate(dispatch, async () => {
        if (!token || !messageIDs.length) {
          throw new Error('missing token or messageIDs')
        }
        const client = getAuthenticatedClient(token)

        let slice
        let group = 0
        while (
          (slice = messageIDs.slice(
            group * maxRequestsPerBatch,
            group * maxRequestsPerBatch + maxRequestsPerBatch
          )).length
        ) {
          const steps = []
          for (const id of slice) {
            const req = new Request(`/me/messages/${id}`, {
              method: 'DELETE',
            })
            steps.push({
              id: steps.length + 1,
              request: req,
              dependsOn: steps.length ? [steps.length] : undefined,
            // dependsOn: steps.length > 4 ? [steps.length - 4] : undefined
            })
          }
          const content = new MSGraph.BatchRequestContent(steps)
          await client.api('/$batch').post(await content.getContent())
          group++
        }
        dispatch({
          type: actions.delete_messages,
          data: messageIDs,
        })
        try {
          await Api.delete(`/mail?ids=${messageIDs.join(',')}`)
          for (const id of messageIDs) {
            dispatch({
              type: actions.delete_linked_message,
              data: id,
            })
          }
        } catch (e) {
          if (e.message.indexOf('could not find mail conversation') === -1) { throw e }
        }
        dispatch(fetchMailbox(token, mailbox))
      })
    }

export const moveMessages =
  (token, fromBox, toBox, messageIDs) => (dispatch, getState) => {
    return workstate(dispatch, async () => {
      if (!token || !messageIDs.length || !fromBox || !toBox) {
        throw new Error('missing token or fromBox or toBox or messageIDs')
      }
      const client = getAuthenticatedClient(token)
      let slice
      let group = 0
      const responses = []
      while (
        (slice = messageIDs.slice(
          group * maxRequestsPerBatch,
          group * maxRequestsPerBatch + maxRequestsPerBatch
        )).length
      ) {
        const steps = []
        for (const id of slice) {
          const req = new Request(`/me/messages/${id}/move`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
            },
            body: JSON.stringify({
              destinationId: toBox,
            }),
          })
          steps.push({
            id: steps.length + 1,
            request: req,
            dependsOn: steps.length ? [steps.length] : undefined,
            // dependsOn: steps.length > 4 ? [steps.length - 4] : undefined
          })
        }
        const content = new MSGraph.BatchRequestContent(steps)
        const res = await client.api('/$batch').post(await content.getContent())
        const responseContent = new MSGraph.BatchResponseContent(res)
        const iter = responseContent.getResponsesIterator()
        let data = iter.next()
        while (!data.done) {
          responses.push(await data.value[1].json())
          data = iter.next()
        }
        group++
      }
      dispatch({
        type: actions.delete_messages,
        data: messageIDs,
      })
      dispatch({
        type: actions.add_messages,
        data: responses,
      })
      dispatch(fetchMailbox(token, fromBox))
      dispatch(fetchMailbox(token, toBox))
    })
  }

export const setActiveDraft = (data) => ({
  type: actions.set_active_draft,
  data,
})

export const createDraft = (token, data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    if (!token) throw new Error('missing token')
    const client = getAuthenticatedClient(token)
    const res = await client.api(`${ENDPOINT}/messages`).post({
      ...data,
    })
    const mailRes = await Api.post('/mail', {
      message_id: res.id,
      conversation_id: res.conversationId,
      to_recipients: res.toRecipients.map((x) => x.emailAddress),
      cc_recipients: res.ccRecipients.map((x) => x.emailAddress),
      bcc_recipients: res.bccRecipients.map((x) => x.emailAddress),
      has_attachments: res.hasAttachments,
      public: false,
      content: res.body.content,
    })
    dispatch({
      type: actions.set_linked_messages,
      data: [mailRes.data],
    })
    dispatch(setActiveDraft(res))
  })
}

export const createReplyDraft = (token, messageID) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    if (!token || !messageID) throw new Error('missing token or messageID')
    const client = getAuthenticatedClient(token)
    const currentDraft = getState().mailDraft
    if (currentDraft.id) {
      await client.api(`${ENDPOINT}/messages/${currentDraft.id}`).delete()
      await Api.delete(`/mail?ids=${currentDraft.id}`)
      dispatch({
        type: actions.delete_linked_message,
        data: [currentDraft.id],
      })
    }
    const res = await client
      .api(`${ENDPOINT}/messages/${messageID}/createReply`)
      .post({})
    const mailRes = await Api.post('/mail', {
      message_id: res.id,
      conversation_id: res.conversationId,
      to_recipients: res.toRecipients.map((x) => x.emailAddress),
      cc_recipients: res.ccRecipients.map((x) => x.emailAddress),
      has_attachments: res.hasAttachments,
      public: false,
      content: res.body.content,
    })
    dispatch({
      type: actions.set_linked_messages,
      data: [mailRes.data],
    })
    dispatch(setActiveDraft(res))
  })
}

export const createReplyAllDraft =
  (token, messageID) => (dispatch, getState) => {
    return workstate(dispatch, async () => {
      if (!token || !messageID) throw new Error('missing token or messageID')
      const client = getAuthenticatedClient(token)
      const currentDraft = getState().mailDraft
      if (currentDraft.id) {
        await client.api(`${ENDPOINT}/messages/${currentDraft.id}`).delete()
        await Api.delete(`/mail?ids=${currentDraft.id}`)
        dispatch({
          type: actions.delete_linked_message,
          data: [currentDraft.id],
        })
      }
      const res = await client
        .api(`${ENDPOINT}/messages/${messageID}/createReplyAll`)
        .post({})
      const mailRes = await Api.post('/mail', {
        message_id: res.id,
        conversation_id: res.conversationId,
        to_recipients: res.toRecipients.map((x) => x.emailAddress),
        cc_recipients: res.ccRecipients.map((x) => x.emailAddress),
        has_attachments: res.hasAttachments,
        public: false,
        content: res.body.content,
      })
      dispatch({
        type: actions.set_linked_messages,
        data: [mailRes.data],
      })
      dispatch(setActiveDraft(res))
    })
  }

export const createForwardDraft =
  (token, messageID) => (dispatch, getState) => {
    return workstate(dispatch, async () => {
      if (!token || !messageID) throw new Error('missing token or messageID')
      const client = getAuthenticatedClient(token)
      const currentDraft = getState().mailDraft
      if (currentDraft.id) {
        await client.api(`${ENDPOINT}/messages/${currentDraft.id}`).delete()
        await Api.delete(`/mail?ids=${currentDraft.id}`)
        dispatch({
          type: actions.delete_linked_message,
          data: [currentDraft.id],
        })
      }
      const res = await client
        .api(`${ENDPOINT}/messages/${messageID}/createForward`)
        .post({})
      const mailRes = await Api.post('/mail', {
        message_id: res.id,
        conversation_id: res.conversationId,
        to_recipients: res.toRecipients.map((x) => x.emailAddress),
        cc_recipients: res.ccRecipients.map((x) => x.emailAddress),
        has_attachments: res.hasAttachments,
        public: false,
        content: res.body.content,
      })
      dispatch({
        type: actions.set_linked_messages,
        data: [mailRes.data],
      })
      dispatch(setActiveDraft(res))
    })
  }

export const updateDraft =
  (token, draftID, data, mailData = {}) =>
    (dispatch, getState) => {
      return workstate(dispatch, async () => {
        if (!token || !draftID) throw new Error('missing token or draftID')
        const client = getAuthenticatedClient(token)
        const res = await client.api(`${ENDPOINT}/messages/${draftID}`).patch({
          ...data,
        })
        const mailRes = await Api.patch(`/mail/${res.id}`, {
          content: res.body.content,
          to_recipients: res.toRecipients.map((x) => x.emailAddress),
          cc_recipients: res.ccRecipients.map((x) => x.emailAddress),
          has_attachments: res.hasAttachments,
          ...mailData,
        })
        dispatch({
          type: actions.set_linked_messages,
          data: [mailRes.data],
        })
        dispatch(setActiveDraft(res))
        dispatch({
          type: actions.add_messages,
          data: [res],
        })
      })
    }

export const deleteDraft = (token, draftID) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    if (!token || !draftID) throw new Error('missing token or draftID')
    const client = getAuthenticatedClient(token)
    await client.api(`${ENDPOINT}/messages/${draftID}`).delete()
    await Api.delete(`/mail?ids=${draftID}`)
    dispatch({
      type: actions.delete_linked_message,
      data: [draftID],
    })
    dispatch(setActiveDraft({ id: null }))
  })
}

const readAsBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () =>
      resolve(reader.result.replace(/^data:.+?;base64,/, ''))
    reader.readAsDataURL(file)
  })

export const addAttachments =
  (token, draftID, files) => (dispatch, getState) => {
    return workstate(dispatch, async () => {
      if (!token || !draftID) throw new Error('missing token or draftID')
      const client = getAuthenticatedClient(token)

      const small = files.filter((x) => x.size < 3500000)
      const big = files.filter((x) => x.size >= 3500000)

      for (const file of small) {
        await client.api(`${ENDPOINT}/messages/${draftID}/attachments`).post({
          '@odata.type': '#microsoft.graph.fileAttachment',
          name: file.name,
          contentBytes: await readAsBase64(file),
        })
      }

      for (const file of big) {
        const res = await client
          .api(
            `${ENDPOINT}/messages/${draftID}/attachments/createUploadSession`
          )
          .version('beta')
          .post({
            AttachmentItem: {
              attachmentType: 'file',
              name: file.name,
              size: file.size,
            },
          })

        const upURL = res.uploadUrl
        for (const part of Files.chunkParams(file.size)) {
          await fetch(upURL, {
            method: 'PUT',
            body: file.slice(part.bstart, part.bend + 1),
            headers: {
              'Content-Length': part.clen,
              'Content-Range': part.cr,
            },
          })
        }
      }

      const res = await client
        .api(`${ENDPOINT}/messages/${draftID}`)
        .expand('attachments')
        .get()
      dispatch(setActiveDraft(res))
      const mailRes = await Api.patch(`/mail/${res.id}`, {
        has_attachments: res.hasAttachments,
        attachments: res.attachments.map((x) => ({
          id: x.id,
          name: x.name,
          size: x.size,
          inline:
            x.isInline &&
            x['@odata.type'] !== '#microsoft.graph.referenceAttachment',
        })),
      })
      dispatch({
        type: actions.set_linked_messages,
        data: [mailRes.data],
      })
      dispatch({
        type: actions.add_messages,
        data: [res],
      })
    })
  }

export const deleteAttachment =
  (token, draftID, attachmentID) => (dispatch, getState) => {
    return workstate(dispatch, async () => {
      if (!token || !draftID || !attachmentID) {
        throw new Error('missing token or draftID or attachmentID')
      }
      const client = getAuthenticatedClient(token)
      await client
        .api(`${ENDPOINT}/messages/${draftID}/attachments/${attachmentID}`)
        .delete()
      const res = await client
        .api(`${ENDPOINT}/messages/${draftID}`)
        .expand('attachments')
        .get()
      dispatch(setActiveDraft(res))
      const mailRes = await Api.patch(`/mail/${res.id}`, {
        has_attachments: res.hasAttachments,
        attachments: res.attachments.map((x) => ({
          id: x.id,
          name: x.name,
          size: x.size,
          inline:
            x.isInline &&
            x['@odata.type'] !== '#microsoft.graph.referenceAttachment',
        })),
      })
      dispatch({
        type: actions.set_linked_messages,
        data: [mailRes.data],
      })
      dispatch({
        type: actions.add_messages,
        data: [res],
      })
      dispatch({
        type: actions.remove_attachment,
        data: {
          messageID: draftID,
          attachmentID,
        },
      })
    })
  }

export const sendDraft =
  (token, draftID, data = {}, mailData = {}) =>
    (dispatch, getState) => {
      return workstate(dispatch, async () => {
        if (!token || !draftID) throw new Error('missing token or draftID')

        const client = getAuthenticatedClient(token)
        const item = await client.api(`${ENDPOINT}/messages/${draftID}`).patch({
          ...data,
        })
        await client.api(`${ENDPOINT}/messages/${draftID}/send`).post({})
        let sentSearch
        do {
          await Timers.wait(DUMB_EMAIL_SENT_DELAY) // ms graph says its immediate, but it is not
          sentSearch = await client
            .api(`${ENDPOINT}/mailFolders/sentitems/messages`)
            .filter(`conversationId eq '${item.conversationId}'`)
            .top(1)
            .select(['id'])
            .get()
        } while (!sentSearch.value.length)
        const sent = sentSearch.value.pop()
        const attachments = await client
          .api(`${ENDPOINT}/messages/${sent.id}/attachments`)
          .select(['name', 'size', 'isInline'])
          .get()

        const mailRes = await Api.post(`/mail/${draftID}/send`, {
          message_id: sent.id,
          // conversation_id: item.conversationId,
          // to_recipients: item.toRecipients.map(x => x.emailAddress),
          has_attachments: item.hasAttachments,
          attachments: attachments.value.map((x) => ({
            id: x.id,
            name: x.name,
            size: x.size,
            inline:
            x.isInline &&
            x['@odata.type'] !== '#microsoft.graph.referenceAttachment',
          })),
          // public: false,
          ...mailData,
        })
        dispatch({
          type: actions.set_linked_messages,
          data: [mailRes.data],
        })
        dispatch(setActiveDraft({ id: null }))
        dispatch(push('/mail/sent'))
      })
    }

export const _sendReplyOrForwardDraft = async (
  dispatch,
  token,
  messageID,
  draftID,
  data,
  mailData,
  path
) => {
  if (!token || !draftID || !messageID) {
    throw new Error('missing token or draftID or messageID')
  }
  const client = getAuthenticatedClient(token)
  const newDraft = await client
    .api(`${ENDPOINT}/messages/${messageID}/${path}`)
    .post({})
  const newBody = {
    contentType: 'html',
    content: data.body.content + '\n' + newDraft.body.content,
  }
  const item = await client.api(`${ENDPOINT}/messages/${newDraft.id}`).patch({
    ...data,
    body: newBody,
  })
  await client.api(`${ENDPOINT}/messages/${draftID}`).delete()
  await client.api(`${ENDPOINT}/messages/${newDraft.id}/send`).post({})
  let sentSearch
  do {
    await Timers.wait(DUMB_EMAIL_SENT_DELAY) // ms graph says its immediate, but it is not
    sentSearch = await client
      .api(`${ENDPOINT}/mailFolders/sentitems/messages`)
      .filter(`conversationId eq '${item.conversationId}'`)
      .top(1)
      .select(['id', 'uniqueBody'])
      .get()
  } while (!sentSearch.value.length)
  const sent = sentSearch.value.pop()
  const attachments = await client
    .api(`${ENDPOINT}/messages/${sent.id}/attachments`)
    .select(['name', 'size', 'isInline'])
    .get()

  const mailRes = await Api.post(`/mail/${item.id}/send`, {
    message_id: sent.id,
    // conversation_id: item.conversationId,
    // to_recipients: item.toRecipients.map(x => x.emailAddress),
    content: sent.uniqueBody.content,
    has_attachments: item.hasAttachments,
    attachments: attachments.value.map((x) => ({
      id: x.id,
      name: x.name,
      size: x.size,
      inline:
        x.isInline &&
        x['@odata.type'] !== '#microsoft.graph.referenceAttachment',
    })),
    // public: false,
    ...mailData,
  })
  dispatch({
    type: actions.set_linked_messages,
    data: [mailRes.data],
  })
}

export const sendReplyDraft =
  (token, messageID, draftID, data, mailData = {}) =>
    (dispatch, getState) => {
      return workstate(dispatch, async () => {
        await _sendReplyOrForwardDraft(
          dispatch,
          token,
          messageID,
          draftID,
          data,
          mailData,
          'createReply'
        )
        dispatch(setActiveDraft({ id: null }))
        dispatch(push('/mail/inbox'))
      })
    }

export const sendReplyAllDraft =
  (token, messageID, draftID, data, mailData = {}) =>
    (dispatch, getState) => {
      return workstate(dispatch, async () => {
        await _sendReplyOrForwardDraft(
          dispatch,
          token,
          messageID,
          draftID,
          data,
          mailData,
          'createReplyAll'
        )
        dispatch(setActiveDraft({ id: null }))
        dispatch(push('/mail/inbox'))
      })
    }

export const sendForwardDraft =
  (token, messageID, draftID, data, mailData = {}) =>
    (dispatch, getState) => {
      return workstate(dispatch, async () => {
        await _sendReplyOrForwardDraft(
          dispatch,
          token,
          messageID,
          draftID,
          data,
          mailData,
          'createForward'
        )
        dispatch(setActiveDraft({ id: null }))
        dispatch(push('/mail/inbox'))
      })
    }

export const addUploadedFileAsAttachment =
  (token, draftID, resource, resourceID, file) => (dispatch, getState) => {
    return workstate(dispatch, async () => {
      const client = getAuthenticatedClient(token)
      if (!draftID) throw new Error('missing draftID')

      let res = await Api.post(`/files/share/${file.fileId}`, {
        resource,
        resourceId: resourceID,
        paths: [file.path],
      })

      const first = res.data[0]

      await client
        .api(`${ENDPOINT}/messages/${draftID}/attachments`)
        .version('beta')
        .post({
          '@odata.type': '#microsoft.graph.referenceAttachment',
          name: file.name,
          permission: 'view',
          providerType: 'other',
          sourceUrl: window.location.origin + '/share/' + first.group,
          isFolder: first.isFolder || false,
        })

      res = await client
        .api(`${ENDPOINT}/messages/${draftID}`)
        .expand('attachments')
        .get()
      dispatch(setActiveDraft(res))
      const mailRes = await Api.patch(`/mail/${res.id}`, {
        has_attachments: res.hasAttachments,
        attachments: res.attachments.map((x) => ({
          id: x.id,
          name: x.name,
          size: x.size,
          inline:
            x.isInline &&
            x['@odata.type'] !== '#microsoft.graph.referenceAttachment',
        })),
      })
      dispatch({
        type: actions.set_linked_messages,
        data: [mailRes.data],
      })
      dispatch({
        type: actions.add_messages,
        data: [res],
      })
    })
  }

for (const a of _actions) {
  actions[a] = a
}
export default actions
