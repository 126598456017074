import React from 'react'
import Icon from '@mdi/react'

export const Tab = ({ icon, active, children, ...props }) => {
  return (
    <div className={'tool-tab' + (active ? ' active' : '')} {...props}>
      {icon && <Icon path={icon} />}
      <span>{children}</span>
    </div>
  )
}
