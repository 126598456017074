// import demoDealFieldsData from '../util/demo-dealfields'
// import demoPersonsData from '../util/demo-dealpersons'
import { workstate, setRelatedItems } from './index'
import { Api, Files } from 'src/util'
import { push } from 'connected-react-router'
// import { getCalculatedRentRollValue, getBoundValue } from 'src/Input/Group'

const _actions = [
  'set_deals',
  'set_users',
  'set_activities',
  'add_deal_detail',
  'remove_deal',
  'set_deal_fields',
  'set_flows',
  'set_deal_participants',
  'set_deal_activities',
  'set_deal_followers',
  'set_persons_detail',
  'set_deal_templates',
  'set_rent_roll_fields',
  'set_rent_rolls',
  'set_income_expenses_fields',
  'update_deal_field',
  'delete_rent_roll',
  'delete_deal_template',
  'set_deal_total_counts',
  'set_deal_export_requirements',
]
const actions = {}

export const fetchDeals =
  (id, userId, offset = 0, filter = '') =>
    (dispatch, getState) => {
      return workstate(dispatch, `fetch-deals-pipeline-${id}`, async () => {
        const json = await Api.get(
        `/pipelines/${id}/deals?skip=${offset}${
          userId ? `&user=${userId}` : ''
        }${filter ? `&filter=${filter}` : ''}`,
        ).paginate(`deals_pipeline-${id}-${userId}`, dispatch, getState)
        if (!json.data.length) return

        dispatch({
          type: actions.set_deals,
          data: json.data,
        })
        if (json.additional_data && json.additional_data.related_items) {
          dispatch(setRelatedItems(json.additional_data.related_items))
        }
      })
    }

export const searchDeals =
  (search = '', userId, offset = 0, filter = '') =>
    (dispatch, getState) => {
      return workstate(dispatch, `fetch-deals-${userId}-${search}`, async () => {
        dispatch({
          type: actions.set_deal_total_counts,
          data: {},
        })
        const json = await Api.get(
        `/deals?skip=${offset}${search ? `&search=${search}` : ''}${
          userId ? '&user=' + userId : ''
        }${filter ? `&filter=${filter}` : ''}`,
        null,
        {},
        ).paginate(`deals_search-${userId}-${search}`, dispatch, getState)
        if (!json.data.length) return

        dispatch({
          type: actions.set_deals,
          data: json.data,
        })
        if (json.additional_data) {
          const additional = json.additional_data
          if (additional.related_items) {
            dispatch(setRelatedItems(json.additional_data.related_items))
          }
          if (additional.totals) {
            dispatch({
              type: actions.set_deal_total_counts,
              data: additional.totals,
            })
          }
        }
      })
    }

export const fetchDealsInStage =
  (id, offset = 0, filter = '') =>
    (dispatch, getState) => {
      return workstate(dispatch, `fetch-stage-deals-${id}`, async () => {
        const json = await Api.get(
        `/stages/${id}/deals?skip=${offset}${
          filter ? `&filter=${filter}` : ''
        }`,
        null,
        {},
        ).paginate(`deals_stage-${id}-${filter}`, dispatch, getState)
        if (!json.data.length) return

        dispatch({
          type: actions.set_deals,
          data: json.data,
        })
        if (json.additional_data && json.additional_data.related_items) {
          dispatch(setRelatedItems(json.additional_data.related_items))
        }
      })
    }

export const fetchDeal = (id) => (dispatch, getState) => {
  return workstate(dispatch, 'fetch-deal', async () => {
    const json = await Api.get(`/deals/${id}`, null, {})

    dispatch({
      type: actions.add_deal_detail,
      data: [json.data],
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const createDeal =
  ({ files_to_upload, rent_roll_files, ...data }) =>
    (dispatch, getState) => {
      return workstate(dispatch, 'create-deal', async () => {
      // const { dealTemplates, dealFields, incomeExpensesFields, rentRollFields } = getState()
        const d = { ...data }
        delete d.files_to_upload
        delete d.rent_roll_files

        // both of these are handled by the new context thing
        for (const k in d.rent_roll) {
          for (const row of d.rent_roll[k]) {
            delete row.id
          }
        }

        const json = await Api.post('/deals', d)

        if (files_to_upload?.length) {
          await dispatch(
            uploadFilesToPhotosFolder(
              'deals',
              json.data.id,
              '/',
              files_to_upload,
              null,
              '/photos',
            ),
          )
        }
        if (rent_roll_files) {
          const uploaded = {}
          for (const i in rent_roll_files) {
            const files = rent_roll_files[i].filter((x) => x instanceof File)
            if (!files.length) continue
            const up = await dispatch(
              uploadFilesToPhotosFolder(
                'deals',
                json.data.id,
                '/',
                files,
                null,
                '/Rent rolls',
              ),
            )
            uploaded[i] = up.map((x) => ({ id: x.fileId, path: x.name }))
          }
          const json2 = await Api.patch(`/deals/${json.data.id}`, {
            rent_roll_files: uploaded,
          })
          dispatch({
            type: actions.add_deal_detail,
            data: [json2.data],
          })
        } else {
          dispatch({
            type: actions.add_deal_detail,
            data: [json.data],
          })
        }

        dispatch(push(`/deals/${json.data.id}`))
      })
    }

export const updateDeal =
  (id, { rent_roll_files, ...data }) =>
    (dispatch, getState) => {
      return workstate(dispatch, 'update-deal', async () => {
        delete data.id
        delete data.created_at
        delete data.updated_at
        delete data.fully_loaded
        delete data.files_to_upload
        for (const k in data.rent_roll) {
          for (const row of data.rent_roll[k]) {
            delete row.id
          }
        }
        if (rent_roll_files) {
          const files = {}
          for (const i in rent_roll_files) {
            const newFiles = rent_roll_files[i].filter((x) => x instanceof File)
            if (newFiles.length) {
              const up = await dispatch(
                uploadFilesToPhotosFolder(
                  'deals',
                  id,
                  '/',
                  newFiles,
                  null,
                  '/Rent rolls',
                ),
              )
              files[i] = up.map((x) => ({ id: x.fileId, path: x.name }))
            } else {
              files[i] = []
            }

            const existingFiles = rent_roll_files[i].filter(
              (x) => !(x instanceof File),
            )
            if (existingFiles.length) {
              files[i].push(...existingFiles)
            }
          }

          data.rent_roll_files = files
        }

        const json = await Api.patch(`/deals/${id}`, data)

        dispatch({
          type: actions.add_deal_detail,
          data: [json.data],
        })
      })
    }

export const closeDeal = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, 'close-deal', async () => {
    const json = await Api.post(`/closings/${id}`, data)

    dispatch({
      type: actions.add_deal_detail,
      data: [json.data],
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const deleteDeal = (id, returnToPipeline) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    await Api.delete(`/deals/${id}`, null, {})

    dispatch({
      type: actions.remove_deal,
      data: id,
    })

    if (returnToPipeline) {
      dispatch(push(`/pipelines/${returnToPipeline}`))
    }
  })
}

export const fetchDealFields = () => (dispatch, getState) => {
  return workstate(dispatch, 'fetch-deal-fields', async () => {
    const json = await Api.get('/dealFields', null, {})

    dispatch({
      type: actions.set_deal_fields,
      data: json.data,
    })
  })
}

export const copyDeal = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.post(`/deals/${id}/copy`, null, {})

    dispatch({
      type: actions.add_deal_detail,
      data: [json.data],
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
    dispatch(push(`/deals/${json.data.id}`))
  })
}

export const fetchDealExportRequirements = (id) => (dispatch) => {
  return workstate(dispatch, 'fetch-deal-export-requirements', async () => {
    const json = await Api.get(`/deals/${id}/export/package/requirements`)

    dispatch({
      type: actions.set_deal_export_requirements,
      data: {
        id,
        data: json.data,
      },
    })

    return json.data
  })
}

export const fetchDealParticipants = (id) => (dispatch, getState) => {
  /* dispatch({
    type: actions.set_deal_participants,
    data: {
      key: 913,
      value: demoParticipantsData.data
    }
  }) */
}

export const fetchDealActivities = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get(`/deals/${id}/activities`)

    dispatch({
      type: actions.set_activities,
      data: json.data,
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const fetchDealFollowers = (id) => (dispatch, getState) => {
  /* dispatch({
    type: actions.set_deal_followers,
    data: {
      key: 913,
      value: demoFollowersData.data.map(x => x.user_id)
    }
  })
  dispatch(fetchUsers(demoFollowersData.data.map(x => x.user_id))) */
}

export const fetchDealPersons = (id) => (dispatch, getState) => {
  // dispatch({
  //   type: actions.set_persons_detail,
  //   data: demoPersonsData.data
  // })
}

export const fetchDealFlow = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.get(`/deals/${id}/flow`, null, {}).paginate(
      `deals_flow-${id}`,
      dispatch,
      getState,
    )

    dispatch({
      type: actions.set_flows,
      data: json.data,
    })
    if (json.additional_data && json.additional_data.related_items) {
      dispatch(setRelatedItems(json.additional_data.related_items))
    }
  })
}

export const fetchDealTemplates = () => (dispatch, getState) => {
  return workstate(dispatch, 'fetch-deal-templates', async () => {
    const json = await Api.get('/dealTemplates', null, {})

    dispatch({
      type: actions.set_deal_templates,
      data: json.data,
    })
  })
}
export const updateDealTemplate = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const d = { ...data }
    delete d.id
    delete d.owner_id
    delete d.system_field
    delete d.created_at
    delete d.updated_at
    const json = await Api.patch(`/dealTemplates/${id}`, d, {})

    dispatch({
      type: actions.set_deal_templates,
      data: [json.data],
    })
  })
}
export const createDealTemplate = (data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.post('/dealTemplates', data, {})

    dispatch({
      type: actions.set_deal_templates,
      data: [json.data],
    })
  })
}
export const deleteDealTemplate = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    await Api.delete(`/dealTemplates/${id}`)

    dispatch({
      type: actions.delete_deal_template,
      data: id,
    })
  })
}

export const fetchRentRolls = () => (dispatch, getState) => {
  return workstate(dispatch, 'fetch-rent-rolls', async () => {
    const json = await Api.get('/rentRolls', null, {})

    dispatch({
      type: actions.set_rent_rolls,
      data: json.data,
    })
  })
}
export const updateRentRoll = (id, data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const d = { ...data }
    delete d.id
    delete d.owner_id
    delete d.system_field
    delete d.created_at
    delete d.updated_at
    const json = await Api.patch(`/rentRolls/${id}`, d, {})

    dispatch({
      type: actions.set_rent_rolls,
      data: [json.data],
    })
  })
}
export const createRentRoll = (data) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    const json = await Api.post('/rentRolls', data, {})

    dispatch({
      type: actions.set_rent_rolls,
      data: [json.data],
    })
  })
}
export const deleteRentRoll = (id) => (dispatch, getState) => {
  return workstate(dispatch, async () => {
    await Api.delete(`/rentRolls/${id}`)

    dispatch({
      type: actions.delete_rent_roll,
      data: id,
    })
  })
}

export const fetchRentRollFields = () => (dispatch, getState) => {
  return workstate(dispatch, 'fetch-rent-roll-field', async () => {
    const json = await Api.get('/rentRollFields', null, {})

    dispatch({
      type: actions.set_rent_roll_fields,
      data: json.data,
    })
  })
}

export const fetchIncomeExpensesFields = () => (dispatch, getState) => {
  return workstate(dispatch, 'fetch-ie-fields', async () => {
    const json = await Api.get('/incomeExpensesFields', null, {})

    dispatch({
      type: actions.set_income_expenses_fields,
      data: json.data,
    })
  })
}

const doUploadFiles = async (
  dispatch,
  resource,
  resourceID,
  path,
  tree,
  resourceOverride,
) => {
  const { files } = tree
  const uploaded = []
  const names = []
  for (const file of files) {
    const res = await Api.post('/files/createUploadSession', {
      resource,
      resource_id: resourceID,
      path,
      name: file.name,
      size: file.size,
    })

    const upURL = res.uploadUrl
    let upResult
    for (const part of Files.chunkParams(file.size)) {
      upResult = await fetch(upURL, {
        method: 'PUT',
        body: file.slice(part.bstart, part.bend + 1),
        headers: {
          'Content-Length': part.clen,
          'Content-Range': part.cr,
          'Content-Type': 'application/octet-stream',
        },
      })
      if (!upResult.status.toString().startsWith('2')) {
        throw new Error(upResult.status)
      }
    }
    names.push(file.name)
    uploaded.push(upResult.json())
  }
  const res = await Promise.all(uploaded)
  return res.map((x, i) => ({
    ...x,
    name: names[i],
  }))
}

const uploadFilesToPhotosFolder =
  (resource, resourceID, path, files, resourceOverride, folder) =>
    (dispatch, getState) => {
      return workstate(dispatch, 'post-deal-files', async () => {
        for (const file of files) {
          if (!file.name || !file.stream) {
            throw new Error('file must contain name and stream')
          }
        }

        const tree = {
          files,
        }

        if (folder !== '/') {
          await Api.post(
          `/files/${resource}/${resourceID}/children?path=${path}`,
          {
            name: folder.substr(1),
          },
          )
        }

        return await doUploadFiles(
          dispatch,
          resource,
          resourceID,
          folder,
          tree,
          resourceOverride,
        )
      })
    }

for (const a of _actions) {
  actions[a] = a
}
export default actions
