import React, { useRef, useEffect } from 'react'
import { Calendar } from 'react-big-calendar'
import { navigate as nav } from 'react-big-calendar/lib/utils/constants'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import dateFnsLocalizer from './dateFnsLocalizer'
import {
  format,
  parse,
  startOfWeek,
  getDay,
  subHours,
  isYesterday,
} from 'date-fns'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'
import { mdiChevronLeft, mdiChevronRight, mdiCalendarToday } from '@mdi/js'
import Event from './Event'

import './index.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

const locales = {
  'en-US': require('date-fns/locale/en-US'),
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const DragDropCalendar = withDragAndDrop(Calendar)

const DayToolbar = ({ label, onNavigate, ...props }) => {
  const navigate = (action) => {
    onNavigate(action)
  }
  return (
    <div className='calendar-day-toolbar flex-container'>
      <span className='date flex'>{label}</span>
      <div className='btn-group btn-group-h'>
        <button
          className='btn btn-square btn-clear no-shadow'
          onClick={(e) => navigate(nav.PREVIOUS)}
        >
          <Icon path={mdiChevronLeft} />
        </button>
        <button
          className='btn btn-square btn-clear no-shadow'
          onClick={(e) => navigate(nav.TODAY)}
        >
          <Icon path={mdiCalendarToday} />
        </button>
        <button
          className='btn btn-square btn-clear no-shadow'
          onClick={(e) => navigate(nav.NEXT)}
        >
          <Icon path={mdiChevronRight} />
        </button>
      </div>
    </div>
  )
}

export const DayCalendar = ({
  date,
  events,
  min,
  onAddEvent,
  onMoveEvent,
  onResizeEvent,
  onNavigate,
}) => {
  const scrollTime = useRef(null)
  useEffect(() => {
    if (events.length && events[0].allDay) {
      if (!scrollTime.current) {
        scrollTime.current = subHours(new Date(), 4)
        if (isYesterday(scrollTime.current)) scrollTime.current = new Date()
      }
    }
  }, [events])
  return (
    <div className='calendar calendar-day flex'>
      <DragDropCalendar
        date={date}
        components={{
          toolbar: DayToolbar,
          event: Event,
        }}
        formats={{
          dayHeaderFormat: 'cccc, MMM dd',
          timeGutterFormat: 'h a',
        }}
        localizer={localizer}
        startAccessor='start'
        endAccessor='end'
        events={events}
        defaultView={'day'}
        views={['day']}
        step={15}
        timeslots={4}
        scrollToTime={scrollTime.current}
        selectable
        resizable
        onSelectSlot={onAddEvent}
        onEventResize={onResizeEvent}
        onEventDrop={onMoveEvent}
        onNavigate={onNavigate}
      />
    </div>
  )
}

DayCalendar.propTypes = {
  events: PropTypes.array,
  onAddEvent: PropTypes.func.isRequired,
  onMoveEvent: PropTypes.func.isRequired,
  onResizeEvent: PropTypes.func.isRequired,
}

DayCalendar.defaultProps = {
  events: [],
  onNavigate: () => {},
  onAddEvent: () => {},
  onMoveEvent: () => {},
  onResizeEvent: () => {},
}
