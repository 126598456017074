const _actions = ['add_page', 'reset_page']
const actions = {}

export const paginationAddPage = (resourceKey, resourceIds, offset, more) => ({
  type: actions.add_page,
  data: {
    resourceKey,
    resourceIds,
    offset,
    more,
  },
})

export const paginationReset = (resourceKey) => ({
  type: actions.reset_page,
  data: {
    resourceKey,
  },
})

for (const a of _actions) {
  actions[a] = a
}
export default actions
